var Aneox;
(function (Aneox) {
  'use strict';

  var Gallery = (function () {

    var bodyElement;
    var galleryElement;
    var galleryImagesElement;
    var galleryControlsItemsElement;
    
    var isOpen;
    var gallery;
    var activeIndex;
    var isTouchDevice;
    var hammertime;
    
    var activeSection;
    
    var interval;

    function Gallery() {
      bodyElement = $('body');
      galleryElement = $('#gallery');
      galleryImagesElement = $('#gallery-images');
      galleryControlsItemsElement = $('#gallery-controls-items');
      
      isTouchDevice = 'ontouchstart' in window;
    }

    Gallery.prototype.show = function (galleryName) {
      gallery = _.find(Aneox.Data.Data.galleries, { id: galleryName });
      
      if (!gallery || isOpen) return;
      isOpen = true;
      
      activeSection = $('section:visible');
      TweenMax.fromTo(galleryElement, 0.5, { display: 'block', opacity: 0 }, { opacity: 1, onComplete: function () {
          if (activeSection.length) TweenMax.set('section', { clearProps: 'display' });
        } 
      });
      
      galleryImagesElement.empty();
      galleryControlsItemsElement.empty();
      
      _.forEach(gallery.images, function (image) {
        galleryImagesElement.append('<div class="image background-cover" style="background-image: url(' + image.path + ')"></div>');
        galleryControlsItemsElement.append('<li></li>')
      });
      
      activeIndex = 0;
      $('.image', galleryImagesElement).eq(activeIndex).addClass('active');
      $('li', galleryControlsItemsElement).eq(activeIndex).addClass('active');
      
      if (isTouchDevice) {
        hammertime = new Hammer(document.body);
        hammertime.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL, velocity: 0.33 });
        hammertime.on('swipeleft', this.next);
        hammertime.on('swiperight', this.previous);
      }
      
      interval = setInterval(this.next.bind(this, true), 3500);
      
      this.trigger('galleryWillShow');
    };

    Gallery.prototype.close = function () {
      if (!isOpen) return;
      isOpen = false;
      
      if (interval) {
        clearInterval(interval);
        interval = undefined;
      }
      
      if (activeSection.length) TweenMax.set(activeSection, { display: 'block' });
      TweenMax.fromTo(galleryElement, 0.5, { opacity: 1 }, { opacity: 0, display: 'none' });
      
      galleryElement.removeClass('ltr-in').removeClass('ltr-out').removeClass('rtl-in').removeClass('rtl-out');
      
      if (isTouchDevice) {
        hammertime.off('swipeleft');
        hammertime.off('swiperight');
        hammertime.destroy();
        hammertime = undefined;
      }
      
      this.trigger('galleryWillHide');
    };

    Gallery.prototype.previous = function (isInterval) {
      if (!isOpen) return;
      if (isInterval !== true && interval) {
        clearInterval(interval);
        interval = undefined;
      }
      
      $('.image', galleryImagesElement).removeClass('active').removeClass('ltr-in').removeClass('ltr-out').removeClass('rtl-in').removeClass('rtl-out');
      $('li', galleryControlsItemsElement).removeClass('active');
      
      $('.image', galleryImagesElement).eq(activeIndex).addClass('rtl-out');
      
      activeIndex -= 1;
      if (activeIndex < 0) activeIndex = gallery.images.length - 1;
      
      $('.image', galleryImagesElement).eq(activeIndex).addClass('active').addClass('rtl-in');
      $('li', galleryControlsItemsElement).eq(activeIndex).addClass('active');
    };

    Gallery.prototype.next = function (isInterval) {
      if (!isOpen) return;
      if (isInterval !== true && interval) {
        clearInterval(interval);
        interval = undefined;
      }
      
      $('.image', galleryImagesElement).removeClass('active').removeClass('ltr-in').removeClass('ltr-out').removeClass('rtl-in').removeClass('rtl-out');
      $('li', galleryControlsItemsElement).removeClass('active');
      
      $('.image', galleryImagesElement).eq(activeIndex).addClass('ltr-out');
      
      activeIndex += 1;
      if (activeIndex >= gallery.images.length) activeIndex = 0;
      
      $('.image', galleryImagesElement).eq(activeIndex).addClass('active').addClass('ltr-in');
      $('li', galleryControlsItemsElement).eq(activeIndex).addClass('active');
    };

    MicroEvent.mixin(Gallery);

    return Gallery;
  })();

  Aneox.Gallery = Gallery;
})(Aneox || (Aneox = {}));