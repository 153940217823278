var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var Terroir = (function () {

      var self;
      
      var terroir;
      var terroirGalleryButton;
      var terroirVisibility;

      var firstPart;
      var firstPartRenderer;
      var firstPartStage;
      var firstPartSprite;
      var firstPartDissolveFilter;
      
      var hasPlayedThroughAnimation;
      
      function Terroir(animations, resources) {
        self = this;
        this.animations = animations;
        this.resources = resources;
        
        hasPlayedThroughAnimation = {};
        
        terroir = $('#terroir');
        terroirGalleryButton = $('path:eq(0)', '#terroir .gallery-button.left svg');
        
        firstPart = $('#terroir-part-1');
        firstPartRenderer = new PIXI.autoDetectRenderer(1153, 664, { clearBeforeRender: false });
        firstPartRenderer.plugins.interaction.destroy();

        firstPart.prepend(firstPartRenderer.view);

        firstPartStage = new PIXI.Container();
        
        firstPartSprite = new PIXI.Sprite(resources['terroir-first-part'].texture);
  
        firstPartStage.addChild(firstPartSprite);
  
        firstPartDissolveFilter = new DissolveFilter(resources['home-heightmap'].texture);
  
        firstPartSprite.filters = [firstPartDissolveFilter];
  
        firstPartStage.addChild(firstPartSprite);
      
        firstPartDissolveFilter.clampValues = [1.0,0,0];
        firstPartDissolveFilter.startTimes = [0,0,0];
        firstPartDissolveFilter.coverColor = [0.92156862745, 0.92156862745, 0.92156862745];
        
        firstPartRenderer.render(firstPartStage);
        
        
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.attributeName === 'style') {
              var display = terroir.css('display');
              var previousTerroirVisibility = terroirVisibility;
              terroirVisibility = display !== 'none';
              
              if (!previousTerroirVisibility && display !== 'none') {
                TweenMax.fromTo(terroirGalleryButton, 1, { scale: 0, opacity: 1, transformOrigin: '50% 50%' }, { scale: 36, opacity: 1, yoyo: true, repeat: -1, ease: Power1.easeInOut, force3D: true });
              } else if (previousTerroirVisibility && display === 'none') {
                TweenMax.killTweensOf(terroirGalleryButton);
              }
            }
          });
        });
        observer.observe(terroir[0], { attributes: true });
      }
      
      Terroir.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {
      };
      
      Terroir.prototype.resetAnimations = function () {
        hasPlayedThroughAnimation = {};
        
        TweenMax.set('#terroir-mast .main-logo-light', { display: 'block', opacity: 0, immediateRender: true });
        TweenMax.set('#terroir-mast h2 span,#terroir-mast h2 em', { opacity: 0, y: 20, force3D: true, immediateRender: true });
        TweenMax.set(['#terroir-mast p','#terroir-mast hr'], { opacity: 0, immediateRender: true });
        TweenMax.set('#terroir-mast-background', { scale: 1, force3D: true, immediateRender: true });
        
        TweenMax.set('#terroir-mast-title .title-border-top', { width: 0, force3D: true, immediateRender: true });
        TweenMax.set('#terroir-mast-title .title-border-bottom', { width: 0, force3D: true, immediateRender: true });
        TweenMax.set('#terroir-mast-title .title-border-left', { height: 0, force3D: true, immediateRender: true });
        TweenMax.set('#terroir-mast-title .title-border-right', { height: 0, force3D: true, immediateRender: true });
        
        TweenMax.set('#terroir-part-1-text h3, #terroir-part-1-text p', { opacity: 0, y: 20, force3D: true, immediateRender: true });
        
        TweenMax.set('#terroir-part-2-content .content-mask',  { display: 'block', y: 0, force3D: true, immediateRender: true });
        
        TweenMax.set('#terroir-part-2-content h3, #terroir-part-2-content p', { opacity: 0, y: 20, force3D: true, immediateRender: true });
        
        firstPartDissolveFilter.time = 0;
        firstPartRenderer.render(firstPartStage);
      };
      
      Terroir.prototype.fastForwardAnimations = function () {
        this.resetAnimations();
        this.mastEnterAnimation(true, 0);
        this.firstPartEnterAnimation(true, 0);
        this.secondPartEnterAnimation(true, 0);
      };
    
      Terroir.prototype.mastEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.mast)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();
        
        timeline.to('#terroir-mast .main-logo-light', 3 * timescale, { opacity: 1, y: 0, force3D: true });
        timeline.to('#terroir-mast-background', 6 * timescale, { scale: 1.05, force3D: true, ease: Power3.easeOut }, 0 * timescale);
        timeline.staggerTo('#terroir-mast h2 span,#terroir-mast h2 em', 1 * timescale, { opacity: 1, y: 0, force3D: true }, 0.2, 1.2 * timescale);
        timeline.to(['#terroir-mast p','#terroir-mast hr'], 1 * timescale, { opacity: 0.6, ease: Power1.easeInOut }, 2.2 * timescale);
        
        timeline.to('#terroir-mast-title .title-border-top', 0.4 * timescale, { width: '100%', force3D: true }, 0.4 * timescale);
        timeline.to('#terroir-mast-title .title-border-left', 0.4 * timescale, { height: '100%', force3D: true }, 0.4 * timescale);
        timeline.to('#terroir-mast-title .title-border-bottom', 0.4 * timescale, { width: '100%', force3D: true }, 0.8 * timescale);
        timeline.to('#terroir-mast-title .title-border-right', 0.4 * timescale, { height: '100%', force3D: true }, 0.8 * timescale);
        
        hasPlayedThroughAnimation.mast = true;
      };
      
      Terroir.prototype.firstPartEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.firstPart)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();
        
        timeline.to(firstPartDissolveFilter, 1.0 * timescale, { time: 1, onUpdate: function () { firstPartRenderer.render(firstPartStage); } });
        
        timeline.staggerTo('#terroir-part-1-text h3, #terroir-part-1-text p', 1 * timescale, { y: 0, opacity: 1, ease: Power1.easeInOut, force3D: true }, 0.3 * timescale, 0.5 * timescale);
        
        hasPlayedThroughAnimation.firstPart = true;
      };
      
      Terroir.prototype.secondPartEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.secondPart)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();
        
        var contentHeight = $('#terroir-part-2-content').innerHeight();
        
        timeline.to('#terroir-part-2-content .content-mask', 1 * timescale, { y: contentHeight, display: 'none', ease: Power1.easeInOut, force3D: true }, 0 * timescale);
        
        timeline.staggerTo('#terroir-part-2-content h3, #terroir-part-2-content p', 1 * timescale, { y: 0, opacity: 1, ease: Power1.easeInOut, force3D: true }, 0.3 * timescale, 0.5 * timescale);
        
        hasPlayedThroughAnimation.secondPart = true;
      };

      return Terroir;
    })();

    Sequences.Terroir = Terroir;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));