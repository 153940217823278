var Aneox;
(function (Aneox) {
  (function (Library) {
    'use strict';

    var Fonts = (function () {

      function Fonts() {
        if (_.isEmpty(Aneox.Data.Config.fonts)) return;

        if (Aneox.Data.Config.fonts.custom) {
          if (!_.isArray(Aneox.Data.Config.fonts.custom.families))
            Aneox.Data.Config.fonts.custom.families = [Aneox.Data.Config.fonts.custom.families];

          if (!_.isArray(Aneox.Data.Config.fonts.custom.urls))
            Aneox.Data.Config.fonts.custom.urls = [Aneox.Data.Config.fonts.custom.urls];
        }
        
        var self = this;
        Aneox.Data.Config.fonts.active = function () {
          self.trigger('fontsActive');
        };

        WebFont.load(Aneox.Data.Config.fonts);
      }

      MicroEvent.mixin(Fonts);

      return Fonts;
    })();

    Library.Fonts = Fonts;
  })(Aneox.Library || (Aneox.Library = {}));
})(Aneox || (Aneox = {}));