var Aneox;
(function (Aneox) {
  'use strict';

  var App = (function () {
    
    var self;
      
    var isTouchDevice;
    
    var bodySections;
    
    var mainMenuVisibility;
    var winesMenuVisibility;
    var legalVisibility;
    var scrollRetainCount;
    var lastScrollAction;
    var pageChangeFromScript;
    
    var canScrollBelowWineMap;
    var shouldShowAdvanceIndicator;
    var advanceIndicatorShown;
    var advanceIndicatorTimeline;
    
    var currentNodeId;
    var currentKeyframeId;
    var currentNodeType;
    
    var menuTimeline;

    function App(fonts, locale, router, analytics, animations, gallery, panorama, winesMap, winesPoi, wineSheet, importerContinent) {
      this.fonts = fonts;
      this.locale = locale;
      this.router = router;
      this.analytics = analytics;
      this.animations = animations;
      this.gallery = gallery;
      this.panorama = panorama;
      this.winesMap = winesMap;
      this.winesPoi = winesPoi;
      this.wineSheet = wineSheet;
      this.importerContinent = importerContinent;

      self = this;
      
      // Quick and dirty desktop/mobile check
      if (screen && screen.availWidth < 1024) window.location.href = 'http://mobile.domaine-hudelot-noellat.com';
      
      isTouchDevice = 'ontouchstart' in window;
      
      bodySections = $('section,#gallery,#panorama,#wine-sheet,#importer-continent');
      
      scrollRetainCount = 1;
      
      shouldShowAdvanceIndicator = true;
      
      if ($.cookie('agecheck') === 'true')
        $('#age-check').remove();

      var navMenuList = $('#nav-menu-list');
      
      var now = new Date();
      now.setFullYear(now.getFullYear() - 18);
      var nowText = now.toISOString().substring(0, 10).split('-');
      $('#age-check h2 span').text(nowText[2] + '/' + nowText[1] + '/' + nowText[0]);
      

      this.router.bind('pageChange', function (path, node, nodeLocale) {
        currentNodeId = node.id;
        currentKeyframeId = node.id;
        currentNodeType = node.type;
        
        if (pageChangeFromScript) {
          $('li', navMenuList).removeClass('active');
          $('li[node-id="' + node.id + '"]').addClass('active');
          pageChangeFromScript = false;
          return;
        }
        
        self.locale.setLocale(nodeLocale.locale);
                
        self.hideMainMenu();
        self.hideWinesMenu();
        self.gallery.close();
        self.panorama.close();
        self.winesPoi.close();
        
        if (node.type !== 'wine-sheet')
          self.winesMap.leave();
        
        if (node.id === 'legal') {
          return self.showLegal();
        } else {
          self.hideLegal();
        }
        
        if (node.type === 'wine-sheet') {
          currentKeyframeId = 'wines';
          self.wineSheet.show(node.id);
          if (lastScrollAction !== undefined) return;
        } else {
          self.wineSheet.close();
        }
        
        if (node.type === 'importer-continent') {
          currentKeyframeId = 'importers';
          if (self.animations.sequences) {
            var time = self.animations.sequences.main.keyframes[currentKeyframeId] || 0;
            self.animations.sequences.main.setTime(time, 0.25);
          }
          self.importerContinent.show(node.id);
          if (lastScrollAction !== undefined) return;
        } else {
          self.importerContinent.close();
        }
        
        if (currentKeyframeId === 'importers' || currentKeyframeId === 'contact')
          canScrollBelowWineMap = true;
        
        $('li', navMenuList).removeClass('active');
        $('li[node-id="' + currentKeyframeId + '"]').addClass('active');
        
        if (self.animations.sequences) {
          var time = self.animations.sequences.main.keyframes[currentKeyframeId] || 0;
          
          incrementScrollRetainCount();
          TweenMax.set('#sitewide-fade', { display: 'block', opacity: 0, immediateRender: true });
          
          var timeline = new TimelineMax({ paused: true });
          timeline.to('#sitewide-fade', 0.5, { opacity: 1 });
          if (currentKeyframeId === 'home') timeline.fromTo('#nav-menu-progress-light', 0.25, { opacity: 0, display: 'block' }, { opacity: 1 });
          else timeline.fromTo('#nav-menu-progress-dark', 0.25, { opacity: 0, display: 'block' }, { opacity: 1 });
          timeline.call(function () {
            self.animations.enabled = false;
            resetAnimations();
            hideAdvanceIndicator(true);
            self.animations.sequences.main.setTime(time);
            lastScrollAction = 'pageChange';
          });
          timeline.to('#nav-menu-progress-light,#nav-menu-progress-dark', 0.25, { opacity: 0, display: 'none' });
          timeline.to('#sitewide-fade', 0.5, { opacity: 0, display: 'none', delay: 0.25 });
          timeline.call(function () {
            self.animations.enabled = true;
            self.animations.sequences[currentKeyframeId].mastEnterAnimation();
            shouldShowAdvanceIndicator = true;
            showAdvanceIndicator();
            decrementScrollRetainCount();
          }, undefined, undefined, '-=0.3');
          timeline.play();
        }
      });

      this.locale.bind('localeChange', function (locale) {
        $('#wines-list').empty();
        _.forEach(Aneox.Data.Data.wineCategories, function (wineCategory) {
          var wines = _.where(Aneox.Data.Data.wines, { category: wineCategory.id });
          $('#wines-list').append('<h3>' + wineCategory.name + '</h3>');

          _.forEach(wines, function (wine) {
            $('#wines-list').append('<a class="wine" href="' + self.router.getNodePath(wine.id, locale) + '">' + wine.name + '</a>');
          });
        });
        
        $('#wines-menu-list').empty();
        _.forEach(Aneox.Data.Data.wineCategories, function (wineCategory) {
          var wines = _.where(Aneox.Data.Data.wines, { category: wineCategory.id });
          if ($('#wines-menu-list li').length !== 0) $('#wines-menu-list').append('<li class="separator"></li>');
          
          _.forEach(wines, function (wine) {
            $('#wines-menu-list').append('<li node-id="' + wine.id + '"><a class="wine" href="' + self.router.getNodePath(wine.id, locale) + '"><span translate>' + wine.name + '</span> <div class="wines-button"><i></i></div></a></li>');
          });
        });

        $('#importers-continent-list').empty();
        _.forEach(Aneox.Data.Data.importerContinents, function (importerContinent) {
          var localizedData = _.find(importerContinent.locales, { locale: locale });
          $('#importers-continent-list').append('<a class="continent" href="' + self.router.getNodePath(importerContinent.id, locale) + '">' + localizedData.name + '</a>');
        });
      });
      
      this.gallery.bind('galleryWillShow', incrementScrollRetainCount);
      this.gallery.bind('galleryWillHide', decrementScrollRetainCount);
      
      this.panorama.bind('panoramaWillShow', incrementScrollRetainCount);
      this.panorama.bind('panoramaWillHide', decrementScrollRetainCount);
      
      this.winesMap.bind('winesMapDidEnter', incrementScrollRetainCount);
      this.winesMap.bind('winesMapDidLeave', function () {
        canScrollBelowWineMap = true;
        decrementScrollRetainCount();
      });
      
      this.winesMap.bind('winesMapDidClickPlot', function (node) {
        setPage.call(self, node, true);
      });
      this.winesMap.bind('winesMapDidClickPoi', function (poi) {
        self.winesPoi.show(poi);
      });
      
      this.wineSheet.bind('wineSheetWillShow', incrementScrollRetainCount);
      this.wineSheet.bind('wineSheetWillHide', decrementScrollRetainCount);
      
      this.importerContinent.bind('importerContinentWillShow', incrementScrollRetainCount);
      this.importerContinent.bind('importerContinentWillHide', decrementScrollRetainCount);
      
      this.animations.bind('complete', function (locale) {
        var node = self.router.getNode();
        
        TweenMax.to('#preloader', 0.5, { opacity: 0, display: 'none', delay: 0.5, onComplete: function () {
          $('#preloader').remove();
          if ($('#age-check').length === 0) {
            resetAnimations();
            self.animations.enabled = true;
            self.animations.sequences[currentKeyframeId].mastEnterAnimation();
            showAdvanceIndicator();
            decrementScrollRetainCount();
          }
          }
        });
        handleWindowResize();
        lazySizesConfig.preloadAfterLoad = true;
        
        
        if (node.id !== 'legal') {
          var currentKeyframeId = node.id;
          if (node.type === 'wine-sheet') currentKeyframeId = 'wines';
          if (node.type === 'importer-continent') currentKeyframeId = 'importers';
          
          var time = self.animations.sequences.main.keyframes[currentKeyframeId] || 0;
          
          self.animations.sequences.main.setTime(time);
            
          lastScrollAction = 'pageChange';
        } else {
          self.showLegal();
        }
      });
      
      // Window resize handling
      var handleWindowResize = function () {
        var bodyWidth = document.body.clientWidth;
        var bodyHeight = document.body.clientHeight;
        
        _.forEach($('[screen-height]'), function (element) {
          var sectionHeightElement = $(element);
          var multiplier = parseFloat(sectionHeightElement.attr('screen-height') || '1');
          
          var heightAdd = parseInt(sectionHeightElement.attr('height-add') || '0');
          
          sectionHeightElement.outerHeight(Math.round(bodyHeight * multiplier) + heightAdd);
        });
        
        _.forEach($('[height-set]'), function (element) {
          var sectionHeightElement = $(element);
          var height = parseInt(sectionHeightElement.attr('height-set') || '0');
          
          sectionHeightElement.outerHeight(height);
        });
        
        var domainGalleryWidth = (bodyWidth - 36) * 0.8;
        var domainGalleryHeight = $('#domain-part-2-gallery').height();
        var domainMaskWidth = 795 * domainGalleryHeight / 480;
        
        TweenMax.set('#domain-part-2-gallery-images', { width: Math.round(domainMaskWidth) });
        TweenMax.set('#domain-part-2-gallery-images', { left: Math.round(((domainGalleryWidth - domainMaskWidth) * 0.5)) + 'px' });

        _.forEach(self.animations.sequences, function (sequence) { sequence.handleWindowResize(bodyWidth, bodyHeight); });
        self.winesMap.handleWindowResize();
        
        if (self.winesMap.isActive())
          self.animations.sequences.main.setTime(self.animations.sequences.main.keyframes.winesMap);
        
        lastScrollAction = 'windowResize';
      };
      var throttledWindowResizeHandler = _.throttle(handleWindowResize, 500, {leading: false, trailing: true});
      $(window).resize(throttledWindowResizeHandler);
      
      // Wheel handling
      var position = 0;
      
      $(window).on('mousewheel', function (event) {
        if (scrollRetainCount !== 0) return;
        
        hideAdvanceIndicator();
        
        if (_.contains(['pageChange','windowResize','scrollToKeyframe'], lastScrollAction))
          position = self.animations.sequences.main.timeline.time() / self.animations.sequences.main.duration * self.animations.sequences.main.scrollHeight;
          
        position -= event.deltaY * event.deltaFactor * 1.5 * self.animations.scrollSpeed;
        position = Math.max(0, Math.min(self.animations.sequences.main.scrollHeight, position));

        var scrollPercent = position / self.animations.sequences.main.scrollHeight;
        var time = scrollPercent * self.animations.sequences.main.duration;
        
        if (!canScrollBelowWineMap && time >= self.animations.sequences.main.keyframes.winesMap) {
          time = self.animations.sequences.main.keyframes.winesMap;
          self.winesMap.enter();
        }
        
        self.animations.sequences.main.setTime(time, 0.25);
        checkKeyframes.call(self, time);
        
        lastScrollAction = 'mouseWheel';
      });
      
      if (!isTouchDevice) {
        var mainMenuShownFromMouseMove;
        var winesMenuShownFromMouseMove;
        $(window).on('mousemove', function (event) {
          if (event.pageX < 75 && !mainMenuShownFromMouseMove && !self.panorama.active()) {
            self.showMainMenu();
            mainMenuShownFromMouseMove = true;
          } else if (event.pageX > 200 && !self.panorama.active()) {
            self.hideMainMenu();
            mainMenuShownFromMouseMove = false;
          }
          
          if (event.pageX > document.body.clientWidth - 75 && !winesMenuShownFromMouseMove && currentNodeType === 'wine-sheet') {
            self.showWinesMenu();
            winesMenuShownFromMouseMove = true;
          } else if (event.pageX < document.body.clientWidth - 200 && currentNodeType === 'wine-sheet') {
            self.hideWinesMenu();
            winesMenuShownFromMouseMove = false;
          }
        });
      }
      
      // Touch handling
      var lastPanDirectionY;
      var lastPanDeltaY;
      var lastPanVelocityY;
      var targetPanId;

      if (isTouchDevice) {
        var mc = new Hammer.Manager(document.body);
        mc.add(new Hammer.Pan({ threshold: 0, pointers: 0, direction: Hammer.DIRECTION_VERTICAL }));
        mc.on('panstart panmove panend', function (event) {
          if (scrollRetainCount !== 0) return;
          
          hideAdvanceIndicator();
          
          // if (event.type === 'panstart') targetPanId = event.srcEvent.target.getAttribute('id');
          // if (targetPanId === 'scrollbar' || targetPanId === 'scroller') return;
          
          if (event.type === 'panstart') lastPanDeltaY = 0;
          
          if (_.contains(['pageChange','windowResize','scrollToKeyframe'], lastScrollAction))
            position = self.animations.sequences.main.timeline.time() / self.animations.sequences.main.duration * self.animations.sequences.main.scrollHeight;
          
          var absoluteVelocityY = Math.abs(lastPanVelocityY);
          var scrollDuration;
          
          if (event.type === 'panend' && absoluteVelocityY > 0.5) {
            position -= absoluteVelocityY * lastPanDirectionY * 200;
            scrollDuration = absoluteVelocityY / 3;
          } else {
            position -= event.deltaY - lastPanDeltaY;
          }
          
          position = Math.max(0, Math.min(self.animations.sequences.main.scrollHeight, position));
  
          lastPanDirectionY = (event.deltaY - lastPanDeltaY) >= 0 ? 1 : -1;
          lastPanDeltaY = event.deltaY;
          lastPanVelocityY = event.velocityY;
  
          var scrollPercent = position / self.animations.sequences.main.scrollHeight;
          var time =  scrollPercent * self.animations.sequences.main.duration;
          
          if (!canScrollBelowWineMap && time >= self.animations.sequences.main.keyframes.winesMap) {
            time = self.animations.sequences.main.keyframes.winesMap;
            self.winesMap.enter();
          }
        
          self.animations.sequences.main.setTime(time, scrollDuration);
          checkKeyframes.call(self, time);
          
          lastScrollAction = 'pan';
        });
      }
    }
    
    var incrementScrollRetainCount = function () {
      scrollRetainCount += 1;
    };
    
    var decrementScrollRetainCount = function () {
      scrollRetainCount -= 1;
      scrollRetainCount = Math.max(scrollRetainCount, 0);
    };
    
    var setPage = function (nodeId, isNotKeyframe) {
      if (nodeId === this.router.getNode().id) return;
      
      var path = this.router.getNodePath(nodeId, this.locale.getLocale());
      if (path === undefined) return;
      
      pageChangeFromScript = isNotKeyframe ? false : true;
      this.router.setPath(path);
    }
    
    var checkKeyframes = _.debounce(function (time) {
      var keyframe;
      var keyframeTime = 0;
      
      _.forOwn(this.animations.sequences.main.keyframes, function (value, key) {
        if (time >= value && value >= keyframeTime) {
          keyframe = key;
          keyframeTime = value;
        }
      });
      
      if (!keyframe) return;
      setPage.call(this, keyframe);
    }, 250);
    
    App.prototype.showLegal = function () {
      if (legalVisibility) return;
      legalVisibility = true;
      incrementScrollRetainCount();
      
      TweenMax.fromTo('#legal-notices', 0.5, { display: 'block', opacity: 0 }, { opacity: 1 });
    };
    
    App.prototype.hideLegal = function () {
      if (!legalVisibility) return;
      legalVisibility = false;
      decrementScrollRetainCount();
      
      TweenMax.fromTo('#legal-notices', 0.5, { opacity: 1 }, { opacity: 0, display: 'none' });
    };
    
    App.prototype.ageCheckResult = function (result) {
      if (!result) return;
      
      $.cookie('agecheck', 'true');
      TweenMax.to('#age-check', 0.5, { opacity: 0, onComplete: function () {
        $('#age-check').remove();
          resetAnimations();
          self.animations.enabled = true;
          self.animations.sequences[currentKeyframeId].mastEnterAnimation();
          showAdvanceIndicator();
          decrementScrollRetainCount();
       } 
      });
    };
    
    App.prototype.domainGalleryPrevious = function () {
      self.animations.sequences['domain'].previousGalleryImage();
    };
    
    App.prototype.domainGalleryNext = function () {
      self.animations.sequences['domain'].nextGalleryImage();
    };
    
    App.prototype.showMainMenu = function () {
      if (mainMenuVisibility) return;
      mainMenuVisibility = true;
      
      incrementScrollRetainCount();
      
      var ease = Expo.easeOut;
      var time = 1;
      var force3D = true;
      
      if (menuTimeline === undefined) menuTimeline = new TimelineMax();
      
      menuTimeline.set('#nav-menu-background', { display: 'block' });
      
      menuTimeline.fromTo(bodySections, time, { x: 0 }, { x: 50, ease: ease, force3D: force3D });
      menuTimeline.fromTo('nav', time, { width: 36 }, { width: 200, ease: ease, force3D: force3D }, '-=' + time);
      menuTimeline.fromTo('#nav-menu-image,#nav-menu-text', time, { x: 0, opacity: 1 }, { x: 164, opacity: 0, ease: ease, force3D: force3D }, '-=' + time);
      menuTimeline.fromTo('#nav-menu-logo', time, { display: 'block', opacity: 0 }, { opacity: 1, ease: ease, force3D: force3D }, '-=' + time);
      menuTimeline.staggerFromTo('#nav-menu-list span', time, { opacity: 0, x: -5 }, { opacity: 1, x: 0, ease: ease, force3D: force3D }, 0.05, '-=' + time);
    };
    
    App.prototype.hideMainMenu = function () {
      if (!mainMenuVisibility) return;
      mainMenuVisibility = false;
      
      decrementScrollRetainCount();
      
      var ease = Expo.easeOut;
      var time = 0.8;
      var force3D = true;
      
      if (menuTimeline === undefined) menuTimeline = new TimelineMax();
      
      menuTimeline.set('#nav-menu-background', { display: 'none' });
      
      menuTimeline.fromTo(bodySections, time, { x: 50 }, { x: 0, ease: ease, force3D: force3D });
      menuTimeline.fromTo('nav', time, { width: 200 }, { width: 36, ease: ease, force3D: force3D }, '-=' + time);
      menuTimeline.fromTo('#nav-menu-image,#nav-menu-text', time, { x: 164, opacity: 0 }, { x: 0, opacity: 1, ease: ease, force3D: force3D }, '-=' + time);
      menuTimeline.fromTo('#nav-menu-logo', time, { display: 'block', opacity: 1 }, { opacity: 0, display: 'none', ease: ease, force3D: force3D }, '-=' + time);
      menuTimeline.staggerFromTo($('#nav-menu-list span').get().reverse(), time * 0.5, { opacity: 1, x: 0 }, { opacity: 0, x: -5, ease: ease, force3D: force3D }, 0.05, '-=' + time);
    };
    
    App.prototype.showWinesMenu = function () {
      if (winesMenuVisibility) return;
      winesMenuVisibility = true;
      
      incrementScrollRetainCount();
      
      var ease = Expo.easeOut;
      var time = 1;
      var force3D = true;
      
      if (menuTimeline === undefined) menuTimeline = new TimelineMax();
      
      menuTimeline.set('#wines-menu-background', { display: 'block' });
      
      menuTimeline.fromTo(bodySections, time, { x: 0 }, { x: -100, ease: ease, force3D: force3D });
      menuTimeline.fromTo('#wines-menu', time, { width: 36 }, { width: 350, ease: ease, force3D: force3D }, '-=' + time);
      menuTimeline.fromTo('#wines-menu-image,#wines-menu-text', time, { x: 0, opacity: 1 }, { x: -314, opacity: 0, ease: ease, force3D: force3D }, '-=' + time);
      menuTimeline.fromTo('#wines-menu-logo', time, { display: 'block', opacity: 0 }, { opacity: 1, ease: ease, force3D: force3D }, '-=' + time);
      menuTimeline.fromTo('#wines-menu-list li', time, { x: -314 }, { x: 0, ease: ease, force3D: force3D }, '-=' + time);
      menuTimeline.staggerFromTo('#wines-menu-list span', time, { opacity: 0, x: -5 }, { opacity: 1, x: 0, ease: ease, force3D: force3D }, 0.05, '-=' + time);
      menuTimeline.fromTo('#wines-menu-close', time, { display: 'block', opacity: 0 }, { opacity: 1, ease: ease, force3D: force3D }, '-=' + time);
    };
    
    App.prototype.hideWinesMenu = function () {
      if (!winesMenuVisibility) return;
      winesMenuVisibility = false;
      
      decrementScrollRetainCount();
      
      var ease = Expo.easeOut;
      var time = 0.8;
      var force3D = true;
      
      if (menuTimeline === undefined) menuTimeline = new TimelineMax();
      
      menuTimeline.set('#wines-menu-background', { display: 'none' });
      
      menuTimeline.fromTo(bodySections, time, { x: -100 }, { x: 0, ease: ease, force3D: force3D });
      menuTimeline.fromTo('#wines-menu-close', time, { opacity: 1 }, { opacity: 0, display: 'none', ease: ease, force3D: force3D }, '-=' + time);
      menuTimeline.fromTo('#wines-menu', time, { width: 350 }, { width: 36, ease: ease, force3D: force3D }, '-=' + time);
      menuTimeline.fromTo('#wines-menu-image,#wines-menu-text', time, { x: -314, opacity: 0 }, { x: 0, opacity: 1, ease: ease, force3D: force3D }, '-=' + time);
      menuTimeline.fromTo('#wines-menu-logo', time, { display: 'block', opacity: 1 }, { opacity: 0, display: 'none', ease: ease, force3D: force3D }, '-=' + time);
      menuTimeline.fromTo('#wines-menu-list li', time, { x: 0 }, { x: -314, ease: ease, force3D: force3D }, '-=' + time);
      menuTimeline.staggerFromTo($('#wines-menu-list span').get().reverse(), time * 0.5, { opacity: 1, x: 0 }, { opacity: 0, x: -5, ease: ease, force3D: force3D }, 0.05, '-=' + time);
    };
    
    App.prototype.setWinesMapPlot = function (plot) {
      if (!this.winesMap.isActive()) {
        this.animations.sequences.main.setTime(this.animations.sequences.main.keyframes.winesMap, 0.5);
        this.winesMap.enter();
      }
      
      this.winesMap.setPlot(plot);
    };
    
    App.prototype.closeWineSheet = function (event) {
      if (event && event.preventDefault) event.preventDefault();
      
      this.hideWinesMenu();
      this.wineSheet.close();
      setPage.call(this, 'wines');
    };
    
    App.prototype.enableWinesMapFreeRoaming = function () {
      if (!this.winesMap.isActive()) {
        this.animations.sequences.main.setTime(this.animations.sequences.main.keyframes.winesMap, 0.5);
        this.winesMap.enter();
      }
      
      this.winesMap.enableFreeRoaming();
    };
    
    App.prototype.closeWinesPoi = function () {
        this.winesPoi.close();
    };
    
    var showAdvanceIndicator = function () {
        if (!advanceIndicatorTimeline) advanceIndicatorTimeline = new TimelineMax();
        advanceIndicatorTimeline.clear();
        advanceIndicatorTimeline.invalidate();
        advanceIndicatorTimeline.progress(0);

        setTimeout(function () {
          if (!shouldShowAdvanceIndicator) return;
          advanceIndicatorShown = true;
          
          advanceIndicatorTimeline.set('#' + currentKeyframeId + ' .advance', { display: 'block', opacity: 0, immediateRender: true });
          advanceIndicatorTimeline.set('#' + currentKeyframeId + ' .advance circle', { scale: 0, opacity: 1, transformOrigin: '50% 50%', immediateRender: true });
          
          advanceIndicatorTimeline.to('#' + currentKeyframeId + ' .advance', 0.5, { opacity: 1 });
          advanceIndicatorTimeline.to('#' + currentKeyframeId + ' .advance circle', 1, { scale: 150, opacity: 0, transformOrigin: '50% 50%', repeatDelay: 0.5, repeat: -1, force3D: true });
        }, 1500);
    };
    
    var hideAdvanceIndicator = function (instant) {
      if (shouldShowAdvanceIndicator) {
        shouldShowAdvanceIndicator = false;
        if (advanceIndicatorShown) {
          if (!advanceIndicatorTimeline) advanceIndicatorTimeline = new TimelineMax();
          advanceIndicatorTimeline.clear();
          advanceIndicatorTimeline.invalidate();
          advanceIndicatorTimeline.progress(0);
          
          advanceIndicatorTimeline.to('.advance', instant ? 0 : 0.5, { opacity: 0, display: 'none' });
          advanceIndicatorShown = false;
        }
      }
    };
    
    App.prototype.closeImporterContinent = function (event) {
      if (event && event.preventDefault) event.preventDefault();
      
      this.importerContinent.close();
      setPage.call(this, 'importers');
    };
    
    App.prototype.primaryLanguageClick = function (event) {
      if (mainMenuVisibility) return;
      
      if (event && event.preventDefault) event.preventDefault();
      
      var newPath = this.router.getNodePath(currentNodeId, this.locale.getLocale() === "fr" ? "en" : "fr");
      this.router.setPath(newPath);
    };
    
    var resetAnimations = function () {
      if (self.animations.orderedSequences === undefined) return;
      
      var i;
      var sequence;
      
      var activeSequenceIndex = _.indexOf(self.animations.orderedSequences, self.animations.sequences[currentKeyframeId]);
      
      for (i = 0; i < activeSequenceIndex; i += 1) {
        sequence = self.animations.orderedSequences[i];
        if (sequence.fastForwardAnimations) sequence.fastForwardAnimations();
      }
      
      for (i; i < self.animations.orderedSequences.length; i += 1) {
        sequence = self.animations.orderedSequences[i];
        if (sequence.resetAnimations) sequence.resetAnimations();
      }
    };

    return App;
  })();

  Aneox.App = App;
})(Aneox || (Aneox = {}));