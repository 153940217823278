var Aneox;
(function (Aneox) {
  'use strict';

  var WinesPoi = (function () {

    var self;
    
    var isOpen;
    var winesPoiElement;
    
    function WinesPoi() {
      self = this;
      
      winesPoiElement = $('#wines-poi');
    }
    
    WinesPoi.prototype.show = function (poiId) {
      if (isOpen) return;
      isOpen = true;
      
      $('article', winesPoiElement).hide();
      $('#' + poiId, winesPoiElement).show();
      
      TweenMax.fromTo(winesPoiElement, 0.5, { display: 'block', opacity: 0 }, { opacity: 1 });
      
      
      this.trigger('winesPoiWillShow');
    };
    
    WinesPoi.prototype.close = function () {
      if (!isOpen) return;
      isOpen = false;
      
      TweenMax.fromTo(winesPoiElement, 0.5, { opacity: 1 }, { opacity: 0, display: 'none' });
      
      this.trigger('winesPoiWillHide');
    };
    
    MicroEvent.mixin(WinesPoi);

    return WinesPoi;
  })();

  Aneox.WinesPoi = WinesPoi;
})(Aneox || (Aneox = {}));