var Aneox;
(function (Aneox) {
  'use strict';

  var ImporterContinent = (function () {

    var isOpen;
    
    var importerContinentElement;
    var importerContinentBackgroundGlobalElement;
    var importerContinentBackgroundZoomElement;
    var importerContinentListElement;
    var importerCountryListElement;
    var importerContinentDetailsElement;
    var nameElement;

    var continent;
    var continentLocale;
    
    var activeSection;

    function ImporterContinent(locale) {
      this.locale = locale;

      importerContinentElement = $('#importer-continent');
      importerContinentBackgroundGlobalElement = $('#importer-continent-background-global');
      importerContinentBackgroundZoomElement = $('#importer-continent-background-zoom');
      importerCountryListElement = $('#importer-country-list');
      importerContinentListElement = $('#importer-continent-list');
      nameElement = $('#importer-continent-name');
      importerContinentDetailsElement = $('#importer-continent-details');
    }

    function appendImporter(importer, countryLocale) {
      importerContinentListElement.append(
        '<div class="importer">' +
        (importer.name ? '<p class="importer-name">' + importer.name + '</p>' : '') +
        (importer.address ? '<p class="importer-address">' + importer.address + '</p>' : '') +
        (countryLocale ? '<p class="importer-country">' + countryLocale.name + '</p>' : '') +
        '<div class="importer-contact">' +
        (importer.phone ? '<p class="importer-phone">tel. : <a href="tel:' + importer.phone + '">' + importer.phone + '</a></p>' : '') +
        (importer.phoneBis ? '<p class="importer-phone">tel. : <a href="tel:' + importer.phoneBis + '">' + importer.phoneBis + '</a></p>' : '') +
        (importer.fax ? '<p class="importer-fax">fax. : <a href="tel:' + importer.fax + '">' + importer.fax + '</a></p>' : '') +
        (importer.web ? '<p class="importer-web">site : <a href="' + importer.web + '" target="_blank">' + importer.web + '</a></p>' : '') +
        (importer.mail ? '<p class="importer-mail">email : <a href="mailto:' + importer.mail + '">' + importer.mail + '</a></p>' : '') +
        '</div>' +
        '</div>'
        );
    }

    ImporterContinent.prototype.show = function (continentId) {
      var locale = this.locale.getLocale();

      continent = _.find(Aneox.Data.Data.importerContinents, { id: continentId });
      continentLocale = _.find(continent.locales, { locale: locale });

      if (!continent || !continentLocale || isOpen) return;
      isOpen = true;
      
      activeSection = $('section:visible');
      
      importerContinentDetailsElement.removeClass();
      importerContinentDetailsElement.addClass(continent.detailsPosition);
      
      TweenMax.set(importerContinentElement, { display: 'block', opacity: 0 });
      TweenMax.set(importerContinentBackgroundGlobalElement, { x: '0%', y: '0%', scale: 1 });
      TweenMax.set(importerContinentBackgroundZoomElement, { visibility: 'visible', opacity: 0, x: '0%', y: '0%', scale: 1 });
      TweenMax.set(importerContinentBackgroundGlobalElement, { visibility: 'visible', x: '0%', y: '0%', scale: 1 });
      TweenMax.set(importerContinentDetailsElement, { opacity: 0 });
      
      var timeline = new TimelineMax();
      timeline.to(importerContinentElement, 1, { opacity: 1, delay: 0.25 });
      timeline.to(importerContinentBackgroundGlobalElement, 1, { x: continent.x, y: continent.y, scale: 1.2 }, 1);
      timeline.to(importerContinentBackgroundZoomElement, 1, { opacity: 1, x: continent.x, y: continent.y, scale: 1.2 }, 1);
      timeline.to(importerContinentDetailsElement, 0.5, { opacity: 1 }, 1.5);
      timeline.set(importerContinentBackgroundGlobalElement, { visibility: 'hidden' });
      timeline.call(function () {
        if (activeSection.length) TweenMax.set('section', { clearProps: 'display' });
      });

      nameElement.html(continentLocale.name);

      var importers = _.filter(Aneox.Data.Data.importers, { continentId: continentId });
      var countries = [];

      importerContinentBackgroundZoomElement.css('background-image', 'url("' + continent.background + '")');
      
      importerContinentListElement.empty();
      importerContinentListElement.scrollTop(0);
      _.forEach(importers, function (importer) {
        var country = _.find(Aneox.Data.Data.importerCountries, { id: importer.countryId });
        var countryLocale = _.find(country.locales, { locale: locale });
        if (!_.contains(countries, country)) countries.push(country);

        appendImporter(importer, countryLocale);
      });

      $('li', importerCountryListElement).not(':first').remove();
      _.forEach(countries, function (country) {
        var countryLocale = _.find(country.locales, { locale: locale });
        importerCountryListElement.append('<li onclick="app.importerContinent.toggleFilters(\'' + country.id + '\')">' + countryLocale.name + '</li>')
      });
      
      importerContinentListElement.mCustomScrollbar();
      
      this.trigger('importerContinentWillShow');
    };
    
    ImporterContinent.prototype.close = function () {
      if (!isOpen) return;
      isOpen = false;
      
      if (activeSection.length) TweenMax.set(activeSection, { display: 'block' });
      
      var timeline = new TimelineMax();
      
      timeline.set(importerContinentBackgroundGlobalElement, { visibility: 'visible' });
      timeline.to(importerContinentDetailsElement, 0.5, { opacity: 0 });
      timeline.to(importerContinentBackgroundGlobalElement, 1, { x: '0%', y: '0%', scale: 1 }, 0.5);
      timeline.to(importerContinentBackgroundZoomElement, 1, { opacity: 0, x: '0%', y: '0%', scale: 1 }, 0.5);
      timeline.to(importerContinentElement, 0.5, { opacity: 0, display: 'none' });
      
      importerContinentListElement.mCustomScrollbar('destroy');
      
      importerCountryListElement.removeClass('open');
      $('li', importerCountryListElement).removeClass('active');
      $('li:first', importerCountryListElement).addClass('active');
      
      this.trigger('importerContinentWillHide');
    };

    ImporterContinent.prototype.toggleFilters = function (countryId) {
      if (importerCountryListElement.hasClass('open')) {
        importerCountryListElement.removeClass('open');
      } else {
        importerCountryListElement.addClass('open');
        return;
      }

      var locale = this.locale.getLocale();

      $('li', importerCountryListElement).removeClass('active');
      importerContinentListElement.mCustomScrollbar('destroy');
      if (countryId) {
        $(event.target).addClass('active');

        var importers = _.filter(Aneox.Data.Data.importers, { countryId: countryId });

        importerContinentListElement.empty();
        importerContinentListElement.scrollTop(0);
        _.forEach(importers, function (importer) {
          var country = _.find(Aneox.Data.Data.importerCountries, { id: importer.countryId });
          var countryLocale = _.find(country.locales, { locale: locale });
          appendImporter(importer, countryLocale);
        });
      } else {
        $('li:first', importerCountryListElement).addClass('active');

        var importers = _.filter(Aneox.Data.Data.importers, { continentId: continent.id });

        importerContinentListElement.empty();
        importerContinentListElement.scrollTop(0);
        _.forEach(importers, function (importer) {
          var country = _.find(Aneox.Data.Data.importerCountries, { id: importer.countryId });
          var countryLocale = _.find(country.locales, { locale: locale });
          appendImporter(importer, countryLocale);
        });
      }
      importerContinentListElement.mCustomScrollbar();
    };

    MicroEvent.mixin(ImporterContinent);

    return ImporterContinent;
  })();

  Aneox.ImporterContinent = ImporterContinent;
})(Aneox || (Aneox = {}));