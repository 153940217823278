var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var Vine = (function () {

      var self;
      
      var vine;
      var vineGalleryButton;
      var vineZoomButton;
      var vineVisibility;
      
      var firstPart;
      var firstPartRenderer;
      var firstPartStage;
      var firstPartSprite;
      var firstPartDissolveFilter;
      
      var firstPartIntermediateRenderer;
      var firstPartIntermediateStage;
      var firstPartIntermediateSprite;
      var firstPartIntermediateDissolveFilter;
      
      var hasPlayedThroughAnimation;
      
      function Vine(animations, resources) {
        self = this;
        this.animations = animations;
        this.resources = resources;
        
        hasPlayedThroughAnimation = {};
        
        vine = $('#vine');
        vineGalleryButton = $('path:eq(0)', '#vine .gallery-button.left svg');
        vineZoomButton = $('path:eq(0)', '#vine .zoom-button svg');
        
        firstPart = $('#vine-part-1');
        firstPartRenderer = new PIXI.autoDetectRenderer(1116, 747, { clearBeforeRender: false });
        firstPartRenderer.plugins.interaction.destroy();

        firstPart.prepend(firstPartRenderer.view);

        firstPartStage = new PIXI.Container();
        
        firstPartSprite = new PIXI.Sprite(resources['vine-first-part'].texture);
  
        firstPartStage.addChild(firstPartSprite);
  
        firstPartDissolveFilter = new DissolveFilter(resources['home-heightmap'].texture);
  
        firstPartSprite.filters = [firstPartDissolveFilter];
  
        firstPartStage.addChild(firstPartSprite);
      
        firstPartDissolveFilter.clampValues = [1.0,0,0];
        firstPartDissolveFilter.startTimes = [0,0,0];
        firstPartDissolveFilter.coverColor = [0.92156862745, 0.92156862745, 0.92156862745];
        
        firstPartRenderer.render(firstPartStage);
        
        
        firstPartIntermediateRenderer = new PIXI.autoDetectRenderer(663, 717, { clearBeforeRender: false });
        firstPartIntermediateRenderer.plugins.interaction.destroy();

        firstPart.prepend(firstPartIntermediateRenderer.view);

        firstPartIntermediateStage = new PIXI.Container();
        
        firstPartIntermediateSprite = new PIXI.Sprite(resources['vine-first-part-intermediate'].texture);
  
        firstPartIntermediateStage.addChild(firstPartIntermediateSprite);
  
        firstPartIntermediateDissolveFilter = new DissolveFilter(resources['home-heightmap'].texture);
  
        firstPartIntermediateSprite.filters = [firstPartIntermediateDissolveFilter];
  
        firstPartIntermediateStage.addChild(firstPartIntermediateSprite);
      
        firstPartIntermediateDissolveFilter.clampValues = [1.0,0,0];
        firstPartIntermediateDissolveFilter.startTimes = [0,0,0];
        firstPartIntermediateDissolveFilter.coverColor = [0.92156862745, 0.92156862745, 0.92156862745];
        
        firstPartIntermediateRenderer.render(firstPartIntermediateStage);
        
        
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.attributeName === 'style') {
              var display = vine.css('display');
              var previousVineVisibility = vineVisibility;
              vineVisibility = display !== 'none';
              
              if (!previousVineVisibility && display !== 'none') {
                TweenMax.fromTo(vineGalleryButton, 1, { scale: 0, opacity: 1, transformOrigin: '50% 50%' }, { scale: 36, opacity: 1, yoyo: true, repeat: -1, ease: Power1.easeInOut, force3D: true });
                TweenMax.fromTo(vineZoomButton, 1, { opacity: 0.5, transformOrigin: '50% 50%' }, { opacity: 0, yoyo: true, repeat: -1, ease: Power1.easeInOut, force3D: true });
              } else if (previousVineVisibility && display === 'none') {
                TweenMax.killTweensOf(vineGalleryButton);
                TweenMax.killTweensOf(vineZoomButton);
              }
            }
          });
        });
        observer.observe(vine[0], { attributes: true });
      }
      
      Vine.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {
        
      };
      
      Vine.prototype.resetAnimations = function () {
        hasPlayedThroughAnimation = {};
        
        TweenMax.set('#vine-mast .main-logo-dark', { display: 'block', opacity: 0, y: 50, force3D: true, immediateRender: true });
        TweenMax.set('#vine-mast h2 span,#vine-mast h2 em', { opacity: 0, y: 20, force3D: true, immediateRender: true });
        TweenMax.set('#vine-mast-background', { scale: 1, force3D: true, immediateRender: true });
        
        TweenMax.set('#vine-mast-title .title-border-top', { width: 0, force3D: true, immediateRender: true });
        TweenMax.set('#vine-mast-title .title-border-bottom', { width: 0, force3D: true, immediateRender: true });
        TweenMax.set('#vine-mast-title .title-border-left', { height: 0, force3D: true, immediateRender: true });
        TweenMax.set('#vine-mast-title .title-border-right', { height: 0, force3D: true, immediateRender: true });
        
        TweenMax.set('#vine-part-1-image-overlay', { display: 'none', immediateRender: true });
        TweenMax.set('#vine-part-1 canvas:eq(2)', { display: 'block', immediateRender: true });
        TweenMax.set('#vine-part-1 .zoom-button', { display: 'block', immediateRender: true });
        
        TweenMax.set('#vine-part-1-text-1 h3, #vine-part-1-text-1 p', { opacity: 0, y: 20, force3D: true, immediateRender: true });
        TweenMax.set('#vine-part-1-text-2 h3, #vine-part-1-text-2 p', { opacity: 0, y: 20, force3D: true, immediateRender: true });
        
        TweenMax.set('#vine-part-2-content .content-mask',  { display: 'block', y: 0, force3D: true, immediateRender: true });
        
        TweenMax.set('#vine-part-2-content hr, #vine-part-2-content p', { opacity: 0, y: 20, force3D: true, immediateRender: true });
        
        firstPartDissolveFilter.time = 0;
        firstPartRenderer.render(firstPartStage);
        
        firstPartIntermediateDissolveFilter.time = 0;
        firstPartIntermediateRenderer.render(firstPartIntermediateStage);
      };
      
      Vine.prototype.fastForwardAnimations = function () {
        this.resetAnimations();
        this.mastEnterAnimation(true, 0);
        this.firstPartEnterAnimation(true, 0);
        this.firstPartIntermediateEnterAnimation(true, 0);
        this.secondPartEnterAnimation(true, 0);
      };
      
      Vine.prototype.mastEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.mast)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();
        
        timeline.to('#vine-mast .main-logo-dark', 3 * timescale, { opacity: 1, y: 0, force3D: true });
        timeline.to('#vine-mast-background', 6 * timescale, { scale: 1.05, force3D: true, ease: Power3.easeOut }, 0 * timescale);
        timeline.staggerTo('#vine-mast h2 span,#vine-mast h2 em', 1 * timescale, { opacity: 1, y: 0, force3D: true }, 0.2, 1.2 * timescale);
        
        timeline.to('#vine-mast-title .title-border-top', 0.4 * timescale, { width: '100%', force3D: true }, 0.4 * timescale);
        timeline.to('#vine-mast-title .title-border-left', 0.4 * timescale, { height: '100%', force3D: true }, 0.4 * timescale);
        timeline.to('#vine-mast-title .title-border-bottom', 0.4 * timescale, { width: '100%', force3D: true }, 0.8 * timescale);
        timeline.to('#vine-mast-title .title-border-right', 0.4 * timescale, { height: '100%', force3D: true }, 0.8 * timescale);
        
        hasPlayedThroughAnimation.mast = true;
      };
      
      Vine.prototype.firstPartEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.firstPart)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();
        
        timeline.to(firstPartDissolveFilter, 1.0 * timescale, { time: 1, onUpdate: function () { firstPartRenderer.render(firstPartStage); } });
        
        timeline.staggerTo('#vine-part-1-text-1 h3, #vine-part-1-text-1 p', 1 * timescale, { y: 0, opacity: 1, ease: Power1.easeInOut, force3D: true }, 0.3 * timescale, 0.5 * timescale);
        
        hasPlayedThroughAnimation.firstPart = true;
      };
      
      Vine.prototype.firstPartIntermediateEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.firstPartIntermediate)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();
        
        timeline.to(firstPartIntermediateDissolveFilter, 1.0 * timescale, { time: 1, onUpdate: function () { firstPartIntermediateRenderer.render(firstPartIntermediateStage); } });
        
        timeline.staggerTo('#vine-part-1-text-2 h3, #vine-part-1-text-2 p', 1 * timescale, { y: 0, opacity: 1, ease: Power1.easeInOut, force3D: true }, 0.3 * timescale, 0.5 * timescale);

        hasPlayedThroughAnimation.firstPartIntermediate = true;
      };
      
      Vine.prototype.secondPartEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.secondPart)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();
        
        var contentHeight = $('#vine-part-2-content').innerHeight();
        
        timeline.to('#vine-part-2-content .content-mask', 1 * timescale, { y: contentHeight, display: 'none', ease: Power1.easeInOut, force3D: true }, 0 * timescale);
        
        timeline.staggerTo('#vine-part-2-content hr, #vine-part-2-content p', 1 * timescale, { y: 0, opacity: 1, ease: Power1.easeInOut, force3D: true }, 0.3 * timescale, 0.5 * timescale);
        
        hasPlayedThroughAnimation.secondPart = true;
      };
      
      Vine.prototype.toggleImage = function () {
        if (hasPlayedThroughAnimation.toggleImage) return;
        
        var timeline = new TimelineMax();
        
        timeline.fromTo('#vine-part-1-image-overlay', 1.0, { display: 'block', opacity: 0 }, { opacity: 1 });
        timeline.set('#vine-part-1 canvas:eq(2)', { display: 'none' });
        timeline.set('#vine-part-1 .zoom-button', { display: 'none' });
        
        hasPlayedThroughAnimation.toggleImage = true;
      };

      return Vine;
    })();

    Sequences.Vine = Vine;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));