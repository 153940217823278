var Aneox;
(function (Aneox) {
  'use strict';
  
  // lazysizes - add simple support for background images
  document.addEventListener('lazybeforeunveil', function (e) {
    var bg = e.target.getAttribute('data-bg');
    if (bg) {
      e.target.style.backgroundImage = 'url(' + bg + ')';
    }
  });

  // Attach FastClick
  $(function() {
    FastClick.attach(document.body);
  });
  
  // Main bootlejs init
  var bottle = new Bottle();
  
  bottle.service('Library.Fonts', Aneox.Library.Fonts);
  bottle.service('Library.Locale', Aneox.Library.Locale);
  bottle.service('Library.Router', Aneox.Library.Router);
  bottle.service('Library.Analytics', Aneox.Library.Analytics, 'Library.Router');
  
  bottle.service('Animations', Aneox.Animations, 'Library.Locale');
  bottle.service('Gallery', Aneox.Gallery);
  bottle.service('Panorama', Aneox.Panorama);
  bottle.service('WinesMap', Aneox.WinesMap);
  bottle.service('WinesPoi', Aneox.WinesPoi);
  bottle.service('WineSheet', Aneox.WineSheet, 'Library.Locale');
  bottle.service('ImporterContinent', Aneox.ImporterContinent, 'Library.Locale');
  
  bottle.service('App', Aneox.App, 'Library.Fonts', 'Library.Locale', 'Library.Router', 'Library.Analytics', 'Animations', 'Gallery', 'Panorama', 'WinesMap', 'WinesPoi', 'WineSheet', 'ImporterContinent');
  
  window.app = bottle.container.App;

})(Aneox || (Aneox = {}));