var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var Vinification = (function () {

      var self;
      
      var vinification;
      var vinificationGalleryButton;
      var vinificationVisibility;
      
      var secondPart;
      var secondPartRenderer;
      var secondPartStage;
      var secondPartSprite;
      var secondPartDissolveFilter;
      
      var hasPlayedThroughAnimation;
      
      function Vinification(animations, resources) {
        self = this;
        this.animations = animations;
        this.resources = resources;
        
        vinification = $('#vinification')
        vinificationGalleryButton = $('path:eq(0)', '#vinification .gallery-button.left svg');
        
        hasPlayedThroughAnimation = {};
        
        secondPart = $('#vinification-part-2');
        secondPartRenderer = new PIXI.autoDetectRenderer(840, 823, { clearBeforeRender: false });
        secondPartRenderer.plugins.interaction.destroy();

        secondPart.prepend(secondPartRenderer.view);

        secondPartStage = new PIXI.Container();
        
        secondPartSprite = new PIXI.Sprite(resources['vinification-second-part'].texture);
  
        secondPartStage.addChild(secondPartSprite);
  
        secondPartDissolveFilter = new DissolveFilter(resources['home-heightmap'].texture);
  
        secondPartSprite.filters = [secondPartDissolveFilter];
  
        secondPartStage.addChild(secondPartSprite);
      
        secondPartDissolveFilter.clampValues = [1.0,0,0];
        secondPartDissolveFilter.startTimes = [0,0,0];
        secondPartDissolveFilter.coverColor = [0.92156862745, 0.92156862745, 0.92156862745];
        
        secondPartRenderer.render(secondPartStage);
        
        
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.attributeName === 'style') {
              var display = vinification.css('display');
              var previousVinificationVisibility = vinificationVisibility;
              vinificationVisibility = display !== 'none';
              
              if (!previousVinificationVisibility && display !== 'none') {
                TweenMax.fromTo(vinificationGalleryButton, 1, { scale: 0, opacity: 1, transformOrigin: '50% 50%' }, { scale: 36, opacity: 1, yoyo: true, repeat: -1, ease: Power1.easeInOut, force3D: true });
              } else if (previousVinificationVisibility && display === 'none') {
                TweenMax.killTweensOf(vinificationGalleryButton);
              }
            }
          });
        });
        observer.observe(vinification[0], { attributes: true });
      }
      
      Vinification.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {
        
      };
      
      Vinification.prototype.resetAnimations = function () {
        hasPlayedThroughAnimation = {};
        
        TweenMax.set('#vinification-mast .main-logo-light', { display: 'block', opacity: 0, y: 50, force3D: true, immediateRender: true });
        TweenMax.set('#vinification-mast h2 span,#vinification-mast h2 em', { opacity: 0, y: 20, force3D: true, immediateRender: true });
        TweenMax.set('#vinification-mast-background', { scale: 1, force3D: true, immediateRender: true });
        
        TweenMax.set('#vinification-mast-title .title-border-top', { width: 0, force3D: true, immediateRender: true });
        TweenMax.set('#vinification-mast-title .title-border-bottom', { width: 0, force3D: true, immediateRender: true });
        TweenMax.set('#vinification-mast-title .title-border-left', { height: 0, force3D: true, immediateRender: true });
        TweenMax.set('#vinification-mast-title .title-border-right', { height: 0, force3D: true, immediateRender: true });
        
        TweenMax.set('#vinification-part-1-content .content-mask',  { display: 'block', y: 0, force3D: true, immediateRender: true });
        
        TweenMax.set('#vinification-part-1-content h3, #vinification-part-1-content p', { opacity: 0, y: 20, force3D: true, immediateRender: true });
        
        TweenMax.set('#vinification-part-2-text h3, #vinification-part-2-text p', { opacity: 0, y: 20, force3D: true, immediateRender: true });
        
        secondPartDissolveFilter.time = 0;
        secondPartRenderer.render(secondPartStage);
      };
      
      Vinification.prototype.fastForwardAnimations = function () {
        this.resetAnimations();
        this.mastEnterAnimation(true, 0);
        this.firstPartEnterAnimation(true, 0);
        this.secondPartEnterAnimation(true, 0);
      };
      
      Vinification.prototype.mastEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.mast)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();
        
        timeline.to('#vinification-mast .main-logo-light', 3 * timescale, { opacity: 1, y: 0, force3D: true });
        timeline.to('#vinification-mast-background', 6 * timescale, { scale: 1.05, force3D: true, ease: Power3.easeOut }, 0 * timescale);
        timeline.staggerTo('#vinification-mast h2 span,#vinification-mast h2 em', 1 * timescale, { opacity: 1, y: 0, force3D: true }, 0.2, 1.2 * timescale);
        
        timeline.to('#vinification-mast-title .title-border-top', 0.4 * timescale, { width: '100%', force3D: true }, 0.4 * timescale);
        timeline.to('#vinification-mast-title .title-border-left', 0.4 * timescale, { height: '100%', force3D: true }, 0.4 * timescale);
        timeline.to('#vinification-mast-title .title-border-bottom', 0.4 * timescale, { width: '100%', force3D: true }, 0.8 * timescale);
        timeline.to('#vinification-mast-title .title-border-right', 0.4 * timescale, { height: '100%', force3D: true }, 0.8 * timescale);
        
        hasPlayedThroughAnimation.mast = true;
      };
      
      Vinification.prototype.firstPartEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.firstPart)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();
        
        var contentHeight = $('#vinification-part-1-content').innerHeight();
        
        timeline.to('#vinification-part-1-content .content-mask', 1 * timescale, { y: contentHeight, display: 'none', ease: Power1.easeInOut, force3D: true }, 0 * timescale);
        
        timeline.staggerTo('#vinification-part-1-content h3, #vinification-part-1-content p', 1 * timescale, { y: 0, opacity: 1, ease: Power1.easeInOut, force3D: true }, 0.3 * timescale, 0.5 * timescale);
        
        hasPlayedThroughAnimation.firstPart = true;
      };
      
      Vinification.prototype.secondPartEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.secondPart)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();
        
        timeline.to(secondPartDissolveFilter, 1.0 * timescale, { time: 1, onUpdate: function () { secondPartRenderer.render(secondPartStage); } });
        
        timeline.staggerTo('#vinification-part-2-text h3, #vinification-part-2-text p', 1 * timescale, { y: 0, opacity: 1, ease: Power1.easeInOut, force3D: true }, 0.3 * timescale, 0.5 * timescale);
        
        hasPlayedThroughAnimation.secondPart = true;
      };

      return Vinification;
    })();

    Sequences.Vinification = Vinification;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));