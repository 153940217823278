var Aneox;
(function (Aneox) {
  'use strict';

  var WinesMap = (function () {

    var self;

    var winesMap;
    var winesVisibility;
      
    var isOpen;
    var isTouchDevice;
    var changeEventCount;
    var wrapperElement;
    var overlayElement;
    var svgElement;
    var listElement;
    var imageElement;
    var namesElement;
    var closeElement;
    var panZoom;
    var svgSnap;
    
    var activePlot;
    var activeArea;
    var areas;
    var areaCount;
    
    var canTransitionPlot;
    var freeRoamingMode;
    var freeRoamingNamesVisibility;
    
    var hammertime;

    function WinesMap() {
      self = this;
      
      isTouchDevice = 'ontouchstart' in document.documentElement;
      
      winesMap = $('#wines-map');
      wrapperElement = $('#wines-map-svg');
      overlayElement = $('#wines-map-overlay');
      imageElement = $('#wines-map-image');
      listElement = $('#wines-map-list');
      namesElement = $('#wines-map-names');
      closeElement = $('#wines-map-close');
      
      // Setup areas from data
      areas = {};
      areaCount = 0;
      _.forEach(Aneox.Data.Data.winesMapArea, function (area) {
        if (!_.isArray(area.pois)) area.pois = [area.pois];
        if (!_.isArray(area.plots)) area.plots = [area.plots];
        
        area.mask.selector = '#' + area.mask.id;
        area.index = parseInt(area.index);
        
        _.forEach(area.pois, function (poi) {
          poi.selector = '#Points_Interets #' + poi.id;
        });
        
        _.forEach(area.plots, function (plot) {
          plot.navOnlySelector = '#parcelles_HN_Nav #' + plot.id;
          plot.withLabelSelector = '#parcelles_HN #' + plot.label;
        });
        
        area.maskCenter.x = parseFloat(area.maskCenter.x);
        area.maskCenter.y = parseFloat(area.maskCenter.y);
        
        areas[area.id] = area;
        areaCount += 1;
      });
        
      
      // Load SVG map
      wrapperElement.load('assets/vin/cote_nuits_final.svg', null, function () {
        svgElement = $('#wines-map-svg svg');
        //svgSnap = Snap(svgElement[0]);
        
        $('#parcelles_HN > g', svgElement).css('cursor', 'pointer');
        $('#Points_Interets > g', svgElement).css('cursor', 'pointer');
        $('#parcelles_HN_Nav > g', svgElement).css('cursor', 'pointer');
        
        $('#noms_parcelles', svgElement).css('pointer-events', 'none');
        $('#noms_villages', svgElement).css('pointer-events', 'none');
        
        _.forEach(areas, function (area) {
          _.forEach(area.plots, function (plot) {
            var fullSelector = plot.navOnlySelector + ',' + plot.withLabelSelector;
            
            $(fullSelector, svgElement).click(function (event) {
              if (changeEventCount > 1) return;
              self.trigger('winesMapDidClickPlot', plot.node);
            });
            
            $(fullSelector, svgElement).on('touchend', function (event) {
              if (changeEventCount > 1) return;
              self.trigger('winesMapDidClickPlot', plot.node);
            });
            
            if (!isTouchDevice) {
              $(fullSelector, svgElement).mouseenter(function (event) {
                $(plot.navOnlySelector, svgElement).attr('class', 'hover');
                $(plot.withLabelSelector, svgElement).attr('class', 'hover');
              });
              
              $(fullSelector, svgElement).mouseleave(function (event) {
                $(plot.navOnlySelector, svgElement).attr('class', '');
                $(plot.withLabelSelector, svgElement).attr('class', '');
              });
            }
          });
          
          _.forEach(area.pois, function (poi) {
            $(poi.selector, svgElement).click(function (event) {
              self.trigger('winesMapDidClickPoi', poi.node);
            });
          });
        });

        panZoom = svgElement.panzoom({
          minScale: 1,
          maxScale: 5,
          onZoom: function (event, panzoom, zoom) {
            if (!freeRoamingMode) return;
            changeEventCount = 0;
            
            if (zoom >= 3) {
              if (!freeRoamingNamesVisibility) {
                svgSnap.select('#noms_parcelles').attr({ display: 'block', opacity: 0 }).animate({ opacity: 1 }, 500);
                freeRoamingNamesVisibility = true;
              }
            } else if (freeRoamingNamesVisibility) {
              svgSnap.select('#noms_parcelles').animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
              freeRoamingNamesVisibility = false;
            }
          },
          onChange: function () {
            changeEventCount += 1;
          },
          onEnd: function () {
            setTimeout(function () {
              changeEventCount = 0;
            });
          }
        });
        
        panZoom.panzoom('disable');
        changeEventCount = 0;

        wrapperElement.on('mousewheel.focal', function (e) {
          e.preventDefault();
          var delta = e.delta || e.originalEvent.wheelDelta;
          var zoomOut = delta ? delta < 0 : e.originalEvent.deltaY > 0;
          panZoom.panzoom('zoom', zoomOut, {
            increment: 0.2,
            animate: false,
            focal: e
          });
        });
      });
      
      var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (mutation.attributeName === 'style') {
            var display = winesMap.css('display');
            var previousWinesVisibility = winesVisibility;
            winesVisibility = display !== 'none';
            
            if (!previousWinesVisibility && display !== 'none') {
              self.handleWindowResize();
              if (!isOpen) reset();
            }
          }
        });
      });
      observer.observe(winesMap[0], { attributes: true });
    }
    
    var poiAnimateBack = function () {
      this.select('path').animate({ opacity: 0.5 }, 1000, mina.linear, poiAnimateForth.bind(this));
    };
    
    var poiAnimateForth = function () {
      if ($(this.node).css('display') === 'none') return;
      this.select('path').animate({ opacity: 0 }, 1000, mina.linear, poiAnimateBack.bind(this));
    };

    var reset = function () {
      imageElement.css('display', 'block');
      wrapperElement.css('display', 'none');
    };
    
    var prepare = function (reset) {
      panZoom.panzoom('resetDimensions');
      
      if (!svgSnap) svgSnap = Snap(svgElement[0]);
      
      svgSnap.selectAll('#parcelles_HN > g').attr({ display: 'none' });
      svgSnap.selectAll('#Points_Interets > g').attr({ display: 'none' });
      svgSnap.selectAll('#parcelles_HN_Nav > g').attr({ display: 'none' });
      svgSnap.select('#CHAMBOLLE').attr({ display: 'none' });
      svgSnap.select('#VOUGEOT').attr({ display: 'none' });
      svgSnap.select('#VOSNE').attr({ display: 'none' });
      svgSnap.select('#NUITS').attr({ display: 'none' });
      svgSnap.select('#noms_parcelles').attr({ display: 'none' });
      svgSnap.select('#noms_villages').attr({ display: 'none' });
      svgSnap.select('#limites_appellations').attr({ display: 'none' });
      svgSnap.select('#routes').attr({ display: 'none' });

      svgSnap.select('#noms_villages').attr({ display: 'block', opacity: 1 });
          
      activePlot = undefined;
      activeArea = undefined;
      
      $('li', listElement).removeClass('active');
      
      $('li', namesElement).removeClass('active');
      $('li', namesElement).eq(0).addClass('active');
      
      closeElement.hide();
      TweenMax.set('#wines-map-list li:not(.free-roaming)', { y: 0, opacity: 1, visibility: 'visible', force3D: true });
      TweenMax.set('#wines-map-list li.free-roaming', { y: 0, force3D: true });
      
      if (reset) TweenMax.set(svgElement, { scale: 1.3, x: 0, y: 0, force3D: false });
    };

    WinesMap.prototype.isActive = function () {
      return isOpen;
    };
    
    WinesMap.prototype.handleWindowResize = function () {
      if (!winesVisibility || !panZoom) return;

      if (wrapperElement.css('display') === 'block') panZoom.panzoom('resetDimensions');
      
      // Reposition to area
      if (activeArea !== undefined) {
        var centerRect = svgSnap.select('#viewport').node.getBoundingClientRect();
        var pointRect = svgSnap.select(activeArea.mask.selector).selectAll('path')[1].node.getBoundingClientRect();
  
        var pan = {
          x: -(pointRect.left + pointRect.width * activeArea.maskCenter.x) + (centerRect.left + centerRect.width * 0.5),
          y: -(pointRect.top + pointRect.height * activeArea.maskCenter.y) + (centerRect.top + centerRect.height * 0.5)
        };
        
        var previousMatrix = panZoom.panzoom('getMatrix');
        var previousScale = parseFloat(previousMatrix[0]);
        TweenMax.set(svgElement, { scale: 4, x: (pan.x * 4 / previousScale), y: (pan.y * 4 / previousScale), force3D: false });
      }
      
      // Resize overlays
      var overlayWidth = document.body.clientWidth - 36;//overlayElement.width();
      var overlayHeight = document.body.clientHeight;//overlayElement.height();
      
      var widthRatio = overlayWidth / 1280;
      var heightRatio = overlayHeight / 720;
      
      if (widthRatio < heightRatio) {
        var realHeight = widthRatio * 720;
        var deltaHeight = Math.ceil((overlayHeight - realHeight) * 0.5) + 1;
        
        $('#wines-map-overlay-top,#wines-map-overlay-bottom').height(deltaHeight);
        $('#wines-map-overlay-left,#wines-map-overlay-right').width(0);
      } else {
        var realWidth = heightRatio * 1280;
        var deltaWidth = Math.ceil((overlayWidth - realWidth) * 0.5) + 1;
        
        $('#wines-map-overlay-top,#wines-map-overlay-bottom').height(0);
        $('#wines-map-overlay-left,#wines-map-overlay-right').width(deltaWidth);
      }
    };
    
    WinesMap.prototype.setPlot = function (newPlot) {
      if (newPlot === activePlot || areas[newPlot] === undefined) return;
      
      var wasVisible = wrapperElement.css('display') === 'block';
      imageElement.css('display', 'none');
      wrapperElement.css('display', 'block');
      if (!wasVisible) prepare(true);
      
      activePlot = newPlot;
      activeArea = areas[newPlot];
      
      var previousMatrix = panZoom.panzoom('getMatrix');
      var previousScale = parseFloat(previousMatrix[0]);
      var previousX = parseFloat(previousMatrix[4]);
      var previousY = parseFloat(previousMatrix[5]);
      
      panZoom.panzoom('disable');
      changeEventCount = 0;
      TweenMax.set(svgElement, { scale: previousScale, x: previousX, y: previousY, force3D: false });
      TweenMax.to(closeElement, 0.5, { opacity: 0, display: 'none' });
      
      freeRoamingMode = false;
      svgSnap.select('#noms_villages').animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
      svgSnap.select('#noms_parcelles').animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
      svgSnap.selectAll('#parcelles_HN_Nav > g').animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
      
      _.forEach(areas, function (discardedArea) {
        if (discardedArea.id === activePlot) return;
        
        svgSnap.select(discardedArea.mask.selector).animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
        _.forEach(discardedArea.pois, function (poi) {
          svgSnap.select(poi.selector).animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
        });
        _.forEach(discardedArea.plots, function (plot) {
            svgSnap.select(plot.withLabelSelector).animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
        });
      });
      
      svgSnap.select(activeArea.mask.selector).attr({ display: 'block', opacity: 0 }).animate({ opacity: 1 }, 500);
      _.forEach(activeArea.pois, function (poi) {
        svgSnap.select(poi.selector).attr({ display: 'block', opacity: 0 }).animate({ opacity: 1 }, 500);
        poiAnimateBack.call(svgSnap.select(poi.selector));
      });
      _.forEach(activeArea.plots, function (plot) {
        svgSnap.select(plot.withLabelSelector).attr({ display: 'block', opacity: 0 }).animate({ opacity: 1 }, 500);
      });
      
      $('li', listElement).removeClass('active');
      $('li', listElement).eq(activeArea.index).addClass('active');
      
      $('li', namesElement).removeClass('active');
      $('li', namesElement).eq(activeArea.index + 1).addClass('active');
      
      var centerRect = svgSnap.select('#viewport').node.getBoundingClientRect();
      var pointRect = svgSnap.select(activeArea.mask.selector).selectAll('path')[1].node.getBoundingClientRect();

      var pan = {
        x: -(pointRect.left + pointRect.width * activeArea.maskCenter.x) + (centerRect.left + centerRect.width * 0.5),
        y: -(pointRect.top + pointRect.height * activeArea.maskCenter.y) + (centerRect.top + centerRect.height * 0.5)
      };
      
      TweenMax.to(svgElement, 1, { scale: 4, x: (pan.x * 4 / previousScale), y: (pan.y * 4 / previousScale), force3D: false });
    };
    
    var handleMouseWheel = function (event) {
      if (freeRoamingMode || !canTransitionPlot || !panZoom) return;
      
      var area;
      
      var activeAreaIndex = activeArea !== undefined ? activeArea.index : -1;
      
      if (event.deltaY < 0) {
        var nextAreaIndex = activeAreaIndex + 1;
        if (nextAreaIndex === areaCount) {
          activePlot = undefined;
          activeArea = undefined;
          
          $('li', listElement).removeClass('active');
          
          $('li', namesElement).removeClass('active');
          $('li', namesElement).eq(0).addClass('active');
          
          svgSnap.selectAll('#parcelles_HN > g').animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
          svgSnap.selectAll('#Points_Interets > g').animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
          svgSnap.selectAll('#parcelles_HN_Nav > g').animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
          svgSnap.select('#CHAMBOLLE').animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
          svgSnap.select('#VOUGEOT').animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
          svgSnap.select('#VOSNE').animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
          svgSnap.select('#NUITS').animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
          
          svgSnap.select('#noms_villages').attr({ display: 'block', opacity: 0 }).animate({ opacity: 1 }, 500);
          
          TweenMax.to(svgElement, 1, { scale: 1.3, x: 0, y: 0, force3D: false });
          
          //self.trigger('winesMapDidFinish');
          canTransitionPlot = false;
          setTimeout(function () {
            self.leave.call(self);
          }, 1000);
          return;
        } else {
          area = _.findWhere(areas, { index: nextAreaIndex });
          self.setPlot(area.id);
        }
      } else {
        var previousAreaIndex = activeAreaIndex - 1;
        if (previousAreaIndex < 0) {
          self.leave();
          return;
        } else {
          area = _.findWhere(areas, { index: previousAreaIndex });
          self.setPlot(area.id);
        }
      }
      
      canTransitionPlot = false;
      setTimeout(function () { canTransitionPlot = true; }, 1000);
    };

    WinesMap.prototype.enter = function () {
      if (isOpen) return;
      isOpen = true;

      self.handleWindowResize();
      $(window).on('mousewheel', handleMouseWheel);
      freeRoamingMode = false;
      canTransitionPlot = false;
      setTimeout(function () { canTransitionPlot = true; }, 1000);
      
      if (isTouchDevice) {
        hammertime = new Hammer.Manager(winesMap[0]);
        hammertime.add(new Hammer.Pan({ threshold: 0, pointers: 0, direction: Hammer.DIRECTION_VERTICAL }));
        hammertime.on('panmove', handleMouseWheel);
      }

      this.trigger('winesMapDidEnter');
    };

    WinesMap.prototype.leave = function () {
      if (!isOpen) return;
      isOpen = false;

      canTransitionPlot = false;
      
      var previousMatrix = panZoom.panzoom('getMatrix');
      var previousScale = parseFloat(previousMatrix[0]);
      var previousX = parseFloat(previousMatrix[4]);
      var previousY = parseFloat(previousMatrix[5]);

      $(window).off('mousewheel', handleMouseWheel);
      panZoom.panzoom('disable');
      
      TweenMax.to(closeElement, 0.5, { opacity: 0, display: 'none' });
      TweenMax.to('#wines-map-list li:not(.free-roaming)', 1, { y: 0, opacity: 1, visibility: 'visible', force3D: true });
      TweenMax.to('#wines-map-list li.free-roaming', 1, { y: 0, force3D: true });
      
      changeEventCount = 0;
      TweenMax.set(svgElement, { scale: previousScale, x: previousX, y: previousY, force3D: false });
      TweenMax.to(svgElement, 1, { scale: 1.3, x: 0, y: 0, force3D: false, onComplete: function () {
        imageElement.css('display', 'block');
        wrapperElement.css('display', 'none');
      } });
      
      if (svgSnap) {
        svgSnap.select('#noms_parcelles').animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
        svgSnap.selectAll('#parcelles_HN_Nav > g').animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
      }

      $('li', listElement).removeClass('active');
      
      if (isTouchDevice) {
        hammertime.off('panmove');
        hammertime.destroy();
        hammertime = undefined;
      }
      
      this.trigger('winesMapDidLeave');
    };
    
    WinesMap.prototype.enableFreeRoaming = function () {
      if (freeRoamingMode) return;
      freeRoamingMode = true;
      activePlot = undefined;
      activeArea = undefined;
      
      imageElement.css('display', 'none');
      wrapperElement.css('display', 'block');
      prepare(false);
      
      $('li', listElement).removeClass('active');
      $('li', listElement).last().addClass('active');
      
      $('li', namesElement).removeClass('active');
      
      freeRoamingNamesVisibility = false;
      
      svgSnap.select('#noms_villages').attr({ display: 'block', opacity: 0 }).animate({ opacity: 1 }, 500);
      svgSnap.selectAll('#parcelles_HN_Nav > g').attr({ display: 'block', opacity: 0 }).animate({ opacity: 1 }, 500);
      
      svgSnap.selectAll('#parcelles_HN > g').animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
      svgSnap.selectAll('#Points_Interets > g').animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
      svgSnap.select('#CHAMBOLLE').animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
      svgSnap.select('#VOUGEOT').animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
      svgSnap.select('#VOSNE').animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
      svgSnap.select('#NUITS').animate({ opacity: 0 }, 500, mina.linear, function () { this.attr({ display: 'none' }); });
      
      TweenMax.to('#wines-map-list li:not(.free-roaming)', 1, { y: -170, opacity: 0, visibility: 'hidden', force3D: true });
      TweenMax.to('#wines-map-list li.free-roaming', 1, { y: -170, force3D: true });
      TweenMax.fromTo(closeElement, 0.5, { opacity: 0, display: 'block' }, { opacity: 1 });
      
      panZoom.panzoom('resetDimensions');
      var previousMatrix = panZoom.panzoom('getMatrix');
      var previousScale = parseFloat(previousMatrix[0]);
      var previousX = parseFloat(previousMatrix[4]);
      var previousY = parseFloat(previousMatrix[5]);
      
      TweenMax.set(svgElement, { scale: previousScale, x: previousX, y: previousY, force3D: false });
      
      TweenMax.to(svgElement, 1, { scale: 1.4, x: 100, y: 0, force3D: false, onComplete: function () {
        panZoom.panzoom('enable');
      }
      });
    };

    MicroEvent.mixin(WinesMap);

    return WinesMap;
  })();

  Aneox.WinesMap = WinesMap;
})(Aneox || (Aneox = {}));