var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var Home = (function () {

      var self;

      var home;
      var homeVisibility;
      var homeMast;
      
      var renderer;
      var stage;
      var homeBackgroundSprite;
      var dissolveFilter;
      
      var hasPlayedThroughAnimation;

      function Home(animations, resources) {
        self = this;
        this.animations = animations;
        this.resources = resources;
        
        hasPlayedThroughAnimation = {};
        
        home = $('#home');
        homeMast = $('#home-mast');
        
        renderer = new PIXI.autoDetectRenderer(homeMast.width(), homeMast.height(), { clearBeforeRender: false });
        renderer.plugins.interaction.destroy();

        homeMast.prepend(renderer.view);

        stage = new PIXI.Container();
        
        homeBackgroundSprite = new PIXI.Sprite(resources['home-background'].texture);
  
        stage.addChild(homeBackgroundSprite);
  
        dissolveFilter = new DissolveFilter(resources['home-heightmap'].texture);
  
        homeBackgroundSprite.filters = [dissolveFilter];
  
        stage.addChild(homeBackgroundSprite);
      
        dissolveFilter.clampValues = [1.0,0,0];
        dissolveFilter.startTimes = [0,0,0];
        dissolveFilter.coverColor = [0.92156862745, 0.92156862745, 0.92156862745];
        
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.attributeName === 'style') {
              var display = home.css('display');
              var previousHomeVisibility = homeVisibility;
              homeVisibility = display !== 'none';
              
              if (!previousHomeVisibility && display !== 'none') {
                self.handleWindowResize();
              }
            }
          });
        });
        observer.observe(home[0], { attributes: true });
        
        this.resetAnimations();
      }
      
      Home.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {
        if (!homeVisibility) return;
        
        renderer.resize(homeMast.width(), homeMast.height());
        
        var homeBackgroundSpriteScale = Math.max(homeMast.width() / 1600, homeMast.height() / 900);
  
        homeBackgroundSprite.position.x = Math.round((1600 * homeBackgroundSpriteScale - homeMast.width()) * -0.5);
        homeBackgroundSprite.position.y = Math.round((900 * homeBackgroundSpriteScale - homeMast.height()) * -0.5);
  
        homeBackgroundSprite.scale.x = homeBackgroundSpriteScale;
        homeBackgroundSprite.scale.y = homeBackgroundSpriteScale;
        
        renderer.render(stage);
      };
      
      Home.prototype.resetAnimations = function () {
        hasPlayedThroughAnimation = {};
        
        TweenMax.set('#home-mast .main-logo-dark', { display: 'block', opacity: 0, y: 20, force3D: true, immediateRender: true });
        TweenMax.set(['#home-mast h2 span', '#home-mast h2 em'], { display: 'block', opacity: 0, y: -20, force3D: true, immediateRender: true });
        
        dissolveFilter.time = 0;
        renderer.render(stage);
      };
      
      Home.prototype.fastForwardAnimations = function () {
        this.resetAnimations();
        this.mastEnterAnimation(true, 0);
      };
      
      Home.prototype.mastEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.mast)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();
        
        timeline.to(dissolveFilter, 3.0 * timescale, { time: 1, onUpdate: function () { renderer.render(stage); } });
        timeline.to('#home-mast .main-logo-dark', 3.0 * timescale, { opacity: 1, y: 0, force3D: true }, 0.5 * timescale);
        timeline.staggerTo(['#home-mast h2 span', '#home-mast h2 em'], 2.0 * timescale, { opacity: 1, y: 0, force3D: true }, 0.5 * timescale, 0.5 * timescale);
        
        hasPlayedThroughAnimation.mast = true;
      };

      return Home;
    })();

    Sequences.Home = Home;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));