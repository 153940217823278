var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var Main = (function () {
      
      var initialized;

      function Main(animations) {
        this.animations = animations;
        
        this.timeline = new TimelineMax({ paused: true });
        this.scrollHeight = 0;
        this.duration = 0;
        this.keyframes = {};
      }
      
      Main.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {
        var previousProgress = this.timeline.progress();
        
        TweenMax.killTweensOf(this.timeline);
        this.timeline.clear();
        
        var isTouchDevice = 'ontouchstart' in window;
        var referenceScrollSpeed = isTouchDevice ? 1.0 : 0.8;
        var slowScrollSpeed = isTouchDevice ? 1.0 : 0.6;
        
        // Precompute heights and timings
        var currentTime = 0;

        this.keyframes.home = 0;
        
        // Home
        this.timeline.call(this.animations.sequences.home.mastEnterAnimation, null, this.animations.sequences.home, currentTime);
        
        var ease = Linear.easeNone;
        var force3D = true;
        
        // Home to Domain
        this.timeline.fromTo('#home', 1, { display: 'block' }, { display: 'block', clearProps: 'display,zIndex', ease: ease, force3D: force3D }, currentTime);
        this.timeline.set('#domain', { clearProps: 'display', immediateRender: true }, currentTime);
        this.timeline.to('#domain', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);
        
        this.timeline.fromTo('#home-wrapper', 1, { y: 0 }, { y: -bodyHeight * 0.5, ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#domain-wrapper', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#domain-mast-overlay', 1, { opacity: 0.75 }, { opacity: 0, display: 'none', ease: ease, force3D: force3D }, currentTime);
        
        this.timeline.fromTo('#nav-menu-progress', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);
        
        // Scroll speeds
        this.timeline.fromTo(this.animations, 0.5, { scrollSpeed: slowScrollSpeed }, { scrollSpeed: referenceScrollSpeed, ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo(this.animations, 0.25, { scrollSpeed: referenceScrollSpeed }, { scrollSpeed: slowScrollSpeed, ease: ease, force3D: force3D }, currentTime + 0.5);
        

        currentTime += 1;
        this.keyframes.domain = currentTime;
        
        // Domain
        var domainMastHeight =  $('#domain-mast').innerHeight() / bodyHeight;
        var domainPart1Height =  $('#domain-part-1').innerHeight() / bodyHeight;
        var domainPart2Height = $('#domain-part-2').innerHeight() / bodyHeight;
        var domainWrapperScrollHeight = domainMastHeight + domainPart1Height + domainPart2Height - 1;
        
        this.timeline.fromTo(this.animations, domainMastHeight, { scrollSpeed: slowScrollSpeed }, { scrollSpeed: referenceScrollSpeed, ease: ease, force3D: force3D }, currentTime);
        
        this.timeline.fromTo('#domain-wrapper', domainWrapperScrollHeight, { y: 0 }, { y: -bodyHeight * domainWrapperScrollHeight, ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#domain-mast', domainMastHeight, { y: 0 }, { y: bodyHeight * 0.85, ease: ease, force3D: force3D }, currentTime + domainMastHeight - 1);
        this.timeline.fromTo('#nav-menu-progress', 1, { y: 0 }, { y: -bodyHeight, ease: ease, force3D: force3D }, currentTime + domainMastHeight - 1);
        
        this.timeline.fromTo('#domain-part-1-content-background', domainPart1Height, { y: -bodyHeight * 0.1 }, { y: 0 }, currentTime + domainMastHeight - domainPart1Height);
        this.timeline.fromTo('#domain-part-1-content-background', domainPart1Height, { y: 0 }, { y: bodyHeight * 0.1 }, currentTime + domainMastHeight);
        
        // Animations
        this.timeline.call(this.animations.sequences.domain.mastEnterAnimation, null, this.animations.sequences.domain, currentTime - 0.3);
        this.timeline.call(this.animations.sequences.domain.firstPartEnterAnimation, null, this.animations.sequences.domain, currentTime + domainMastHeight - 0.75);
        this.timeline.call(this.animations.sequences.domain.secondPartEnterAnimation, null, this.animations.sequences.domain, currentTime + domainMastHeight + domainPart1Height - 0.75);
        
        currentTime += domainWrapperScrollHeight;
        
        // Domain to Terroir
        this.timeline.to('#domain', 1, { display: 'block', clearProps: 'display', ease: ease, force3D: force3D }, currentTime);
        this.timeline.set('#terroir',  { clearProps: 'display' }, currentTime);
        this.timeline.to('#terroir', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);
        
        this.timeline.fromTo('#nav-menu-progress', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);
        
        this.timeline.fromTo('#domain-wrapper', 1, { y: -bodyHeight * domainWrapperScrollHeight }, { y: -bodyHeight * (domainWrapperScrollHeight + 0.5), ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#terroir-wrapper', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#terroir-mast-overlay', 1, { opacity: 0.75 }, { opacity: 0, display: 'none', ease: ease, force3D: force3D }, currentTime);
        
        // Scroll speeds
        this.timeline.fromTo(this.animations, 0.25, { scrollSpeed: referenceScrollSpeed }, { scrollSpeed: slowScrollSpeed, ease: ease, force3D: force3D }, currentTime + 0.5);
        
        currentTime += 1;
        this.keyframes.terroir = currentTime;
        
        // Terroir
        var terroirMastHeight = $('#terroir-mast').innerHeight() / bodyHeight;
        var terroirPart1Height = $('#terroir-part-1').innerHeight() / bodyHeight;
        var terroirPart2Height = $('#terroir-part-2').innerHeight() / bodyHeight;
        var terroirWrapperScrollHeight = terroirMastHeight + terroirPart1Height + terroirPart2Height - 1;
        
        this.timeline.fromTo(this.animations, terroirMastHeight, { scrollSpeed: slowScrollSpeed }, { scrollSpeed: referenceScrollSpeed, ease: ease, force3D: force3D }, currentTime);
        
        this.timeline.fromTo('#terroir-wrapper', terroirWrapperScrollHeight, { y: 0 }, { y: -bodyHeight * terroirWrapperScrollHeight, ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#terroir-mast', terroirMastHeight, { y: 0 }, { y: bodyHeight * 0.85, ease: ease, force3D: force3D }, currentTime + terroirMastHeight - 1);
        this.timeline.fromTo('#nav-menu-progress', 1, { y: 0 }, { y: -bodyHeight, ease: ease, force3D: force3D }, currentTime + terroirMastHeight - 1);

        this.timeline.fromTo('#terroir-map', terroirMastHeight, { y: -75 }, { y: 75 }, currentTime);
        this.timeline.fromTo('#terroir-part-2-content-background', terroirPart2Height, { y: -bodyHeight * 0.1 }, { y: 0 }, currentTime + terroirMastHeight + terroirPart1Height - terroirPart2Height);
        this.timeline.fromTo('#terroir-part-2-content-background', terroirPart2Height, { y: 0 }, { y: bodyHeight * 0.1 }, currentTime + terroirMastHeight + terroirPart1Height);
        
        // Animations
        this.timeline.call(this.animations.sequences.terroir.mastEnterAnimation, null, this.animations.sequences.terroir, currentTime - 0.3);
        this.timeline.call(this.animations.sequences.terroir.firstPartEnterAnimation, null, this.animations.sequences.terroir, currentTime + terroirMastHeight - 0.5);
        this.timeline.call(this.animations.sequences.terroir.secondPartEnterAnimation, null, this.animations.sequences.terroir, currentTime + terroirMastHeight + terroirPart1Height - 0.75);

        currentTime += terroirWrapperScrollHeight;
        
        // Terroir to Vine
        this.timeline.to('#terroir', 1, { display: 'block', clearProps: 'display', ease: ease, force3D: force3D }, currentTime);
        this.timeline.set('#vine',  { clearProps: 'display' }, currentTime);
        this.timeline.to('#vine', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);
        
        this.timeline.fromTo('#nav-menu-progress', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);
        
        this.timeline.fromTo('#terroir-wrapper', 1, { y: -bodyHeight * terroirWrapperScrollHeight }, { y: -bodyHeight * (terroirWrapperScrollHeight + 0.5), ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#vine-wrapper', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#vine-mast-overlay', 1, { opacity: 0.75 }, { opacity: 0, display: 'none', ease: ease, force3D: force3D }, currentTime);
        
        // Scroll speeds
        this.timeline.fromTo(this.animations, 0.25, { scrollSpeed: referenceScrollSpeed }, { scrollSpeed: slowScrollSpeed, ease: ease, force3D: force3D }, currentTime + 0.5);
        
        currentTime += 1;
        this.keyframes.vine = currentTime;
        
        // Vine
        var vineMastHeight = $('#vine-mast').innerHeight() / bodyHeight;
        var vinePart1Height = $('#vine-part-1').innerHeight() / bodyHeight;
        var vinePart2Height = $('#vine-part-2').innerHeight() / bodyHeight;
        var vineWrapperScrollHeight = vineMastHeight + vinePart1Height + vinePart2Height - 1;
        
        this.timeline.fromTo(this.animations, vineMastHeight, { scrollSpeed: slowScrollSpeed }, { scrollSpeed: referenceScrollSpeed, ease: ease, force3D: force3D }, currentTime);
  
        this.timeline.fromTo('#vine-wrapper', vineWrapperScrollHeight, { y: 0 }, { y: -bodyHeight * vineWrapperScrollHeight, ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#vine-mast', vineMastHeight, { y: 0 }, { y: bodyHeight * 0.85, ease: ease, force3D: force3D }, currentTime + vineMastHeight - 1);
        this.timeline.fromTo('#nav-menu-progress', 1, { y: 0 }, { y: -bodyHeight, ease: ease, force3D: force3D }, currentTime + vineMastHeight - 1);

        this.timeline.fromTo('#vine-part-2-content-background', vinePart2Height, { y: -bodyHeight * 0.1 }, { y: 0 }, currentTime + vineMastHeight + vinePart1Height - vinePart2Height);
        this.timeline.fromTo('#vine-part-2-content-background', vinePart2Height, { y: 0 }, { y: bodyHeight * 0.1 }, currentTime + vineMastHeight + vinePart1Height);
        
        // Animations
        this.timeline.call(this.animations.sequences.vine.mastEnterAnimation, null, this.animations.sequences.vine, currentTime - 0.3);
        this.timeline.call(this.animations.sequences.vine.firstPartEnterAnimation, null, this.animations.sequences.vine, currentTime + vineMastHeight - 0.5);
        this.timeline.call(this.animations.sequences.vine.firstPartIntermediateEnterAnimation, null, this.animations.sequences.vine, currentTime + vineMastHeight);
        this.timeline.call(this.animations.sequences.vine.secondPartEnterAnimation, null, this.animations.sequences.vine, currentTime + vineMastHeight + vinePart1Height - 0.75);
        
        currentTime += vineWrapperScrollHeight;
        
        // Vine to Vinification
        this.timeline.to('#vine', 1, { display: 'block', clearProps: 'display', ease: ease, force3D: force3D }, currentTime);
        this.timeline.set('#vinification',  { clearProps: 'display' }, currentTime);
        this.timeline.to('#vinification', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);
        
        this.timeline.fromTo('#nav-menu-progress', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);
        
        this.timeline.fromTo('#vine-wrapper', 1, { y: -bodyHeight * vineWrapperScrollHeight }, { y: -bodyHeight * (vineWrapperScrollHeight + 0.5), ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#vinification-wrapper', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#vinification-mast-overlay', 1, { opacity: 0.75 }, { opacity: 0, display: 'none', ease: ease, force3D: force3D }, currentTime);
        
        // Scroll speeds
        this.timeline.fromTo(this.animations, 0.25, { scrollSpeed: referenceScrollSpeed }, { scrollSpeed: slowScrollSpeed, ease: ease, force3D: force3D }, currentTime + 0.5);

        currentTime += 1;
        this.keyframes.vinification = currentTime;
        
        // Vinification
        var vinificationMastHeight = $('#vinification-mast').innerHeight() / bodyHeight;
        var vinificationPart1Height = $('#vinification-part-1').innerHeight() / bodyHeight;
        var vinificationPart2Height = $('#vinification-part-2').innerHeight() / bodyHeight;
        var vinificationWrapperScrollHeight = vinificationMastHeight + vinificationPart1Height + vinificationPart2Height - 1;
        
        this.timeline.fromTo(this.animations, vinificationMastHeight, { scrollSpeed: slowScrollSpeed }, { scrollSpeed: referenceScrollSpeed, ease: ease, force3D: force3D }, currentTime);

        this.timeline.fromTo('#vinification-wrapper', vinificationWrapperScrollHeight, { y: 0 }, { y: -bodyHeight * vinificationWrapperScrollHeight, ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#vinification-mast', vinificationMastHeight, { y: 0 }, { y: bodyHeight * 0.85, ease: ease, force3D: force3D }, currentTime + vinificationMastHeight - 1);
        this.timeline.fromTo('#nav-menu-progress', 1, { y: 0 }, { y: -bodyHeight, ease: ease, force3D: force3D }, currentTime + vinificationMastHeight - 1);

        this.timeline.fromTo('#vinification-part-1-content-background', vinificationPart1Height, { y: -bodyHeight * 0.1 }, { y: 0 }, currentTime + vinificationMastHeight - vinificationPart1Height);
        this.timeline.fromTo('#vinification-part-1-content-background', vinificationPart1Height, { y: 0 }, { y: bodyHeight * 0.1 }, currentTime + vinificationMastHeight);

        // Animations        
        this.timeline.call(this.animations.sequences.vinification.mastEnterAnimation, null, this.animations.sequences.vinification, currentTime - 0.3);
        this.timeline.call(this.animations.sequences.vinification.firstPartEnterAnimation, null, this.animations.sequences.vine, currentTime + vinificationMastHeight - 0.75);
        this.timeline.call(this.animations.sequences.vinification.secondPartEnterAnimation, null, this.animations.sequences.vine, currentTime + vinificationMastHeight + vinificationPart1Height - 0.5);

        currentTime += vinificationWrapperScrollHeight;
        
        // Vinification to Wines
        this.timeline.to('#vinification', 1, { display: 'block', clearProps: 'display', ease: ease, force3D: force3D }, currentTime);
        this.timeline.set('#wines',  { clearProps: 'display' }, currentTime);
        this.timeline.set('#wines-map',  { display: 'none' }, currentTime);
        this.timeline.to('#wines', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);
        
        this.timeline.fromTo('#nav-menu-progress', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);
        
        this.timeline.fromTo('#vinification-wrapper', 1, { y: -bodyHeight * vinificationWrapperScrollHeight }, { y: -bodyHeight * (vinificationWrapperScrollHeight + 0.5), ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#wines-wrapper', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#wines-mast-overlay', 1, { opacity: 0.75 }, { opacity: 0, display: 'none', ease: ease, force3D: force3D }, currentTime);

        // Scroll speeds
        this.timeline.fromTo(this.animations, 0.25, { scrollSpeed: referenceScrollSpeed }, { scrollSpeed: slowScrollSpeed, ease: ease, force3D: force3D }, currentTime + 0.5);

        currentTime += 1;
        this.keyframes.wines = currentTime;
        
        // Wines
        var winesMastHeight = $('#wines-mast').innerHeight() / bodyHeight;
        var winesMapHeight = $('#wines-map').innerHeight() / bodyHeight;
        var winesWrapperScrollHeight = winesMastHeight + winesMapHeight - 1;
        
        this.timeline.fromTo(this.animations, winesMastHeight * 0.5, { scrollSpeed: slowScrollSpeed }, { scrollSpeed: referenceScrollSpeed, ease: ease, force3D: force3D }, currentTime);

        this.timeline.fromTo('#wines-wrapper', winesWrapperScrollHeight, { y: 0 }, { y: -bodyHeight * winesWrapperScrollHeight, ease: ease, force3D: force3D }, currentTime);
        this.timeline.set('#wines-map',  { display: 'block' }, currentTime + winesMastHeight - 1);
        this.timeline.fromTo('#nav-menu-progress', 1, { y: 0 }, { y: -bodyHeight, ease: ease, force3D: force3D }, currentTime + winesMastHeight - 1);
        
        this.timeline.fromTo('#wines-mast-background-bottle-left', winesWrapperScrollHeight, { y: -350 }, { y: 310, ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#wines-mast-background-bottle-right', winesWrapperScrollHeight, { y: -150, scale: 1.2 }, { y: 250, scale: 1.2, ease: ease, force3D: force3D }, currentTime);
        
        // Animations
        this.timeline.call(this.animations.sequences.wines.mastEnterAnimation, null, this.animations.sequences.wines, currentTime - 0.3);
        this.timeline.call(this.animations.sequences.wines.firstPartEnterAnimation, null, this.animations.sequences.wines, currentTime + winesMastHeight - 0.3);
        
        this.keyframes.winesMap = currentTime + winesMastHeight;
        
        currentTime += winesWrapperScrollHeight;
        
        // Wines to Importers
        this.timeline.to('#wines', 1, { display: 'block', clearProps: 'display', ease: ease, force3D: force3D }, currentTime);
        this.timeline.set('#importers',  { clearProps: 'display' }, currentTime);
        this.timeline.to('#importers', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);
        this.timeline.set('#wines-map',  { display: 'none' }, currentTime + 1);
 
        this.timeline.fromTo('#wines-wrapper', 1, { y: -bodyHeight * winesWrapperScrollHeight }, { y: -bodyHeight * (0.5 + winesWrapperScrollHeight), ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#importers-wrapper', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#importers-mast-overlay', 1, { opacity: 0.75 }, { opacity: 0, display: 'none', ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#nav-menu-progress', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);

        // Scroll speeds
        this.timeline.fromTo(this.animations, 0.25, { scrollSpeed: referenceScrollSpeed }, { scrollSpeed: slowScrollSpeed, ease: ease, force3D: force3D }, currentTime + 0.5);

        currentTime += 1;
        this.keyframes.importers = currentTime;
        
        // Importers
        var importersMastHeight = $('#importers-mast').innerHeight() / bodyHeight;
        var importersWrapperScrollHeight = importersMastHeight - 1;
        
        this.timeline.fromTo(this.animations, importersMastHeight, { scrollSpeed: slowScrollSpeed }, { scrollSpeed: referenceScrollSpeed, ease: ease, force3D: force3D }, currentTime);

        this.timeline.fromTo('#importers-wrapper', importersWrapperScrollHeight, { y: 0 }, { y: -bodyHeight * importersWrapperScrollHeight, ease: ease, force3D: force3D }, currentTime);
        
        // Animations
        this.timeline.call(this.animations.sequences.importers.mastEnterAnimation, null, this.animations.sequences.importers, currentTime - 0.3);
        
        currentTime += importersWrapperScrollHeight;
        
        // Importers to Contact
        this.timeline.to('#importers', 1, { display: 'block', clearProps: 'display', ease: ease, force3D: force3D }, currentTime);
        this.timeline.set('#contact',  { clearProps: 'display' }, currentTime);
        this.timeline.to('#contact', 1, { display: 'block', ease: ease, force3D: force3D }, currentTime);
        
        this.timeline.fromTo('#importers-wrapper', 1, { y: -bodyHeight * importersWrapperScrollHeight }, { y: -bodyHeight * (importersWrapperScrollHeight + 0.5), ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#contact-wrapper', 1, { y: bodyHeight }, { y: 0, ease: ease, force3D: force3D }, currentTime);
        this.timeline.fromTo('#contact-mast-overlay', 1, { opacity: 0.75 }, { opacity: 0, display: 'none', ease: ease, force3D: force3D }, currentTime);
        
        this.timeline.call(this.animations.sequences.contact.mastEnterAnimation, null, this.animations.sequences.contact, currentTime + 0.7);

        currentTime += 1;
        this.keyframes.contact = currentTime;
                
        this.duration = this.timeline.totalDuration();
        this.scrollHeight = currentTime * bodyHeight;
        
        if (!initialized) {
          this.timeline.progress(previousProgress);
          this.timeline.invalidate();
          initialized = true;
        } else {
          this.timeline.invalidate();
          this.timeline.progress(previousProgress);
        }
      };

      Main.prototype.setTime = function (time, duration, ease) {
        var safeTime = Math.min(Math.max(time, 0), this.duration);

        TweenMax.killTweensOf(this.timeline);
        if (duration === undefined || duration === null || duration === 0) {
          this.timeline.time(safeTime);
        } else {
          TweenMax.to(this.timeline, duration, { time: safeTime, ease: ease });
        }
      }

      return Main;
    })();

    Sequences.Main = Main;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));