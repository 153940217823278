var Aneox;
(function (Aneox) {
  'use strict';

  var Animations = (function () {

    function Animations(locale) {
      var self = this;

      var sequences;
      var orderedSequences;
      
      Object.defineProperties(this, {
        sequences: {
          get: function () {
            return sequences;
          }
        },
        orderedSequences: {
          get: function () {
            return orderedSequences;
          }
        }
      });
      
      this.enabled = false;
      this.scrollSpeed = 1;
      
      var loader = new PIXI.loaders.Loader();
      loader
      .add('home-background', 'assets/home/fond-90.jpg')
      .add('home-heightmap', 'heightmaps/home.png')
      .add('terroir-first-part', 'assets/terroir/visuel_peinture-90.jpg')
      .add('vine-first-part', 'assets/vigne/tache_peinture_etat01-90.jpg')
      .add('vine-first-part-intermediate', 'assets/vigne/tache_peinture-90.jpg')
      .add('vinification-second-part', 'assets/vinif/peinture-90.jpg')
      .once('complete', function (loader, resources) {
        sequences = {
          'main': new Aneox.Sequences.Main(self, resources, locale),
          'home': new Aneox.Sequences.Home(self, resources, locale),
          'domain': new Aneox.Sequences.Domain(self, resources, locale),
          'terroir': new Aneox.Sequences.Terroir(self, resources, locale),
          'vine': new Aneox.Sequences.Vine(self, resources, locale),
          'vinification': new Aneox.Sequences.Vinification(self, resources, locale),
          'wines': new Aneox.Sequences.Wines(self, resources, locale),
          'importers': new Aneox.Sequences.Importers(self, resources, locale),
          'contact': new Aneox.Sequences.Contact(self, resources, locale)
        };
        
        orderedSequences = [
          sequences['home'],
          sequences['domain'],
          sequences['terroir'],
          sequences['vine'],
          sequences['vinification'],
          sequences['wines'],
          sequences['importers'],
          sequences['contact']
        ];
        
        self.trigger('complete');
      })
      .load();
    }
    
    MicroEvent.mixin(Animations);

    return Animations;
  })();

  Aneox.Animations = Animations;
})(Aneox || (Aneox = {}));