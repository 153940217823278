var Aneox;
(function (Aneox) {
  'use strict';

  var Panorama = (function () {

    var bodyElement;
    var panoramaElement;
    var panoramaImageWrapperElement;
    var panoramaImageElement;
    var panoramaPlaceWrapperElement;
    var panoramaPlacesElement;
    
    var isOpen;
    var panoramaImageWrapperWidth;
    var hammertime;
    
    var activeSection;
    
    var isTouchDevice;
    
    var progress;
    var lastPanDelta;

    function Panorama() {
      bodyElement = $('body');
      panoramaElement = $('#panorama');
      panoramaImageWrapperElement = $('#panorama-image-wrapper');
      panoramaImageElement = $('#panorama-image');
      panoramaPlaceWrapperElement = $('#panorama-place-wrapper');
      panoramaPlacesElement = $('#panorama-places');
      
      isTouchDevice = 'ontouchstart' in window;
    }
    
    var handleMouseMove = function (event) {
      if (event.preventDefault) event.preventDefault();
      
      var pageX = Math.min(event.pointers ? event.pointers[0].pageX : event.pageX, document.body.clientWidth);
      progress = Math.max(0, (pageX - 0)) / (document.body.clientWidth - 0);
      
      TweenMax.set(panoramaImageWrapperElement, { x: -progress * (panoramaImageWrapperWidth - document.body.clientWidth + 0), force3D: true });
      TweenMax.set(panoramaPlacesElement, { x: (0.5 - progress) * 1000, force3D: true });
    };
    
    var handlePanStart = function (event) {
      if (event.preventDefault) event.preventDefault();
      
      lastPanDelta = 0;
    };
    
    var handlePanMove = function (event) {
      if (event.preventDefault) event.preventDefault();
      
      var delta = event.deltaX - lastPanDelta;
      lastPanDelta = event.deltaX;
      
      progress -= delta / document.body.clientWidth;
      progress = Math.max(0, Math.min(1, progress));
      
      TweenMax.set(panoramaImageWrapperElement, { x: -progress * (panoramaImageWrapperWidth - document.body.clientWidth + 0), force3D: true });
      TweenMax.set(panoramaPlacesElement, { x: (0.5 - progress) * 1000, force3D: true });
    };
    
    var handleWindowResize = function () {
      var bodyHeight = document.body.clientHeight;
      
      panoramaImageWrapperWidth = Math.round((bodyHeight - 140) / 812 * 3000) - 0;
      panoramaImageWrapperElement.width(panoramaImageWrapperWidth);
      
      _.forEach($('i', panoramaPlaceWrapperElement), function (element) {
        var bar = $(element);
        var referenceHeight = parseFloat(bar.attr('ref-height') || '120');  
        bar.height(referenceHeight * (bodyHeight - 140) / 812);
      });
    };

    Panorama.prototype.show = function () {
      if (isOpen) return;
      isOpen = true;
      
      activeSection = $('section:visible');
      TweenMax.fromTo(panoramaElement, 0.5, { display: 'block', opacity: 0 }, { opacity: 1, force3D: true, onComplete: function () {
          if (activeSection.length) TweenMax.set('section', { clearProps: 'display' });
        } 
      });
      
      $(window).on('mousemove', handleMouseMove);
      $(window).on('resize', handleWindowResize);
      handleWindowResize();
      
      progress = 0.5;
      TweenMax.set(panoramaImageWrapperElement, { x: -progress * (panoramaImageWrapperWidth - document.body.clientWidth + 0), force3D: true });
      TweenMax.set(panoramaPlacesElement, { x: (0.5 - progress) * 1000, force3D: true });
      
      if (isTouchDevice) {
        hammertime = new Hammer.Manager(panoramaPlaceWrapperElement[0]);
        hammertime.add(new Hammer.Pan({ threshold: 0, pointers: 0, direction: Hammer.DIRECTION_VERTICAL }));
        hammertime.on('panstart', handlePanStart);
        hammertime.on('panmove', handlePanMove);
      }
      
      this.trigger('panoramaWillShow');
    };

    Panorama.prototype.close = function () {
      if (!isOpen) return;
      isOpen = false;
      
      if (activeSection.length) TweenMax.set(activeSection, { display: 'block' });
      TweenMax.fromTo(panoramaElement, 0.5, { opacity: 1 }, { opacity: 0, display: 'none', force3D: true });
      
      $(window).off('mousemove', handleMouseMove);
      $(window).off('resize', handleWindowResize);
      
      if (isTouchDevice) {
        hammertime.off('panstart');
        hammertime.off('panmove');
        hammertime.destroy();
        hammertime = undefined;
      }
      
      this.trigger('panoramaWillHide');
    };
    
    Panorama.prototype.active = function () {
      return isOpen;
    };

    MicroEvent.mixin(Panorama);

    return Panorama;
  })();

  Aneox.Panorama = Panorama;
})(Aneox || (Aneox = {}));