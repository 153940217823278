var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var Domain = (function () {

      var self;
      var hasPlayedThroughAnimation;
      
      var domain;
      var domainVisibility;
      
      var activeGalleryImage;
      var galleryLength;
      var galleryTimeline;
      var galleryInterval;
      
      var isTouchDevice;
      var hammertime;
      
      function updateDomainGallery(locale) {
        var gallery = _.find(Aneox.Data.Data.galleries, { id: 'domain' });
        
        galleryLength = gallery.images.length;
        
        var galleryImages = $('#domain-part-2-gallery-images');
        $('div', galleryImages).not('#domain-part-2-gallery-mask').remove();
        
        _.forEach(gallery.images, function (image) {
          var imageLocale = _.find(image.locales, { locale: locale });
          if (_.isEmpty(imageLocale.legend)) imageLocale.legend = '';
          galleryImages.append('<div class="image background-cover" style="background-image: url(' + image.path + ')"><legend>' + (imageLocale.legend || '')  + '</legend></div>');
        });
      };

      function Domain(animations, resources, locale) {
        self = this;
        this.animations = animations;
        this.resources = resources;
        
        isTouchDevice = 'ontouchstart' in window;
      
        domain = $('#domain');
        
        galleryTimeline = new TimelineMax();
      
        locale.bind('localeChange', function (locale) {
          updateDomainGallery(locale);
        });
        updateDomainGallery(locale.getLocale());
      
        hasPlayedThroughAnimation = {};
        
        
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            if (mutation.attributeName === 'style') {
              var display = domain.css('display');
              var previousDomainVisibility = domainVisibility;
              domainVisibility = display !== 'none';
              
              if (!previousDomainVisibility && display !== 'none') {
                if (hasPlayedThroughAnimation.secondPart && !galleryInterval) galleryInterval = setInterval(self.nextGalleryImage.bind(self, true), 3500);
                if (isTouchDevice) {
                  hammertime = new Hammer(document.body);
                  hammertime.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL, velocity: 0.33 });
                  hammertime.on('swipeleft', self.nextGalleryImage.bind(self));
                  hammertime.on('swiperight', self.previousGalleryImage.bind(self));
                }
              } else if (previousDomainVisibility && display === 'none') {
                clearInterval(galleryInterval);
                galleryInterval = undefined;
                if (isTouchDevice) {
                  hammertime.off('swipeleft');
                  hammertime.off('swiperight');
                  hammertime.destroy();
                  hammertime = undefined;
                }
              }
            }
          });
        });
        observer.observe(domain[0], { attributes: true });
      }
      
      Domain.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {
      };
      
      Domain.prototype.resetAnimations = function () {
        hasPlayedThroughAnimation = {};
        
        TweenMax.set('#domain-mast .main-logo-light', { display: 'block', opacity: 0, y: 50, force3D: true, immediateRender: true });
        TweenMax.set('#domain-mast h2 span', { opacity: 0, y: 20, force3D: true, immediateRender: true });
        TweenMax.set('#domain-mast-background', { scale: 1, force3D: true, immediateRender: true });
        
        TweenMax.set('#domain-mast-title .title-border-top', { width: 0, force3D: true, immediateRender: true });
        TweenMax.set('#domain-mast-title .title-border-bottom', { width: 0, force3D: true, immediateRender: true });
        TweenMax.set('#domain-mast-title .title-border-left', { height: 0, force3D: true, immediateRender: true });
        TweenMax.set('#domain-mast-title .title-border-right', { height: 0, force3D: true, immediateRender: true });
        
        TweenMax.set('#domain-part-1-content .content-mask',  { display: 'block', y: 0, force3D: true, immediateRender: true });
        
        TweenMax.set('#domain-part-1-content h3, #domain-part-1-content p', { opacity: 0, y: 20, force3D: true, immediateRender: true });
        
        galleryTimeline.pause();
        galleryTimeline.clear();
        galleryTimeline.invalidate();
        galleryTimeline.progress(0);
        galleryTimeline.play();  
          
        TweenMax.set('#domain-part-2-gallery', { opacity: 0, force3D: true, immediateRender: true });
        TweenMax.set('#domain-part-2-gallery-images .image:not("#domain-part-2-gallery-mask")', { opacity: 0, scale: 1, force3D: true, immediateRender: true });
        TweenMax.set('#domain-part-2-gallery-images .image:eq(1)', { opacity: 1, scale: 1, force3D: true, immediateRender: true });
        activeGalleryImage = 1;
        
        TweenMax.set('#domain-part-2 h3, #domain-part-2 hr, #domain-part-2 p', { opacity: 0, y: 20, force3D: true, immediateRender: true });
        
        if (galleryInterval) {
          clearInterval(galleryInterval);
          galleryInterval = undefined;
        }
      };
      
      Domain.prototype.fastForwardAnimations = function () {
        this.resetAnimations();
        this.mastEnterAnimation(true, 0);
        this.firstPartEnterAnimation(true, 0);
        this.secondPartEnterAnimation(true, 0);
      };
      
      Domain.prototype.mastEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.mast)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();
        
        timeline.to('#domain-mast .main-logo-light', 3 * timescale, { opacity: 1, y: 0, force3D: true });
        timeline.to('#domain-mast-background', 6 * timescale, { scale: 1.05, force3D: true, ease: Power3.easeOut }, 0 * timescale);
        timeline.staggerTo('#domain-mast h2 span', 1 * timescale, { opacity: 1, y: 0, force3D: true }, 0.2, 1.2 * timescale);
        
        timeline.to('#domain-mast-title .title-border-top', 0.4 * timescale, { width: '100%', force3D: true }, 0.4 * timescale);
        timeline.to('#domain-mast-title .title-border-left', 0.4 * timescale, { height: '100%', force3D: true }, 0.4 * timescale);
        timeline.to('#domain-mast-title .title-border-bottom', 0.4 * timescale, { width: '100%', force3D: true }, 0.8 * timescale);
        timeline.to('#domain-mast-title .title-border-right', 0.4 * timescale, { height: '100%', force3D: true }, 0.8 * timescale);
        
        hasPlayedThroughAnimation.mast = true;
      };
      
      Domain.prototype.firstPartEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.firstPart)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();
        
        var contentHeight = $('#domain-part-1-content').innerHeight();
        
        timeline.to('#domain-part-1-content .content-mask', 1 * timescale, { y: contentHeight, display: 'none', ease: Power1.easeInOut, force3D: true }, 0 * timescale);
        
        timeline.staggerTo('#domain-part-1-content h3, #domain-part-1-content p', 1 * timescale, { y: 0, opacity: 1, ease: Power1.easeInOut, force3D: true }, 0.3 * timescale, 0.5 * timescale);
        
        hasPlayedThroughAnimation.firstPart = true;
      };
      
      Domain.prototype.secondPartEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.secondPart)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();
        
        timeline.to('#domain-part-2-gallery', 2.0 * timescale, { opacity: 1, ease: Power1.easeInOut, force3D: true });
        timeline.staggerTo('#domain-part-2 h3, #domain-part-2 hr, #domain-part-2 p', 1 * timescale, { y: 0, opacity: 1, ease: Power1.easeInOut, force3D: true }, 0.2 * timescale, 0.5 * timescale);
        
        galleryTimeline.to('#domain-part-2-gallery-images .image:eq(1)', 2.0 * timescale, { scale: 1.05, zIndex: 1, force3D: true, ease: Circ.easeOut }, 1.0 * timescale);
        galleryTimeline.call(function () {
          if (!galleryInterval) galleryInterval = setInterval(self.nextGalleryImage.bind(self, true), 3500);
        }, undefined, undefined, 1.0 * timescale);
        
        hasPlayedThroughAnimation.secondPart = true;
      };
      
      Domain.prototype.previousGalleryImage = function (isInterval) {
        if (isInterval !== true && galleryInterval) {
          clearInterval(galleryInterval);
          galleryInterval = undefined;
        }
        
        var previousGalleryImage = activeGalleryImage;
        activeGalleryImage -= 1;
        if (activeGalleryImage < 1) activeGalleryImage = galleryLength;
        
        galleryTimeline.set('#domain-part-2-gallery-images .image:eq(' + previousGalleryImage + ')', { zIndex: 1, force3D: true });
        galleryTimeline.set('#domain-part-2-gallery-images .image:eq(' + activeGalleryImage + ')', { scale: 1, opacity: 1, zIndex: 0, force3D: true });
        galleryTimeline.to('#domain-part-2-gallery-images .image:eq(' + previousGalleryImage + ')', 2, { opacity: 0, scale: 1.10, force3D: true, ease: Circ.easeOut });
        galleryTimeline.to('#domain-part-2-gallery-images .image:eq(' + activeGalleryImage + ')', 2, { scale: 1.05, force3D: true, ease: Circ.easeOut }, '-=2');
      };
      
      Domain.prototype.nextGalleryImage = function (isInterval) {
        if (isInterval !== true && galleryInterval) {
          clearInterval(galleryInterval);
          galleryInterval = undefined;
        }
        
        var previousGalleryImage = activeGalleryImage;
        activeGalleryImage += 1;
        if (activeGalleryImage > galleryLength) activeGalleryImage = 1;
        
        galleryTimeline.set('#domain-part-2-gallery-images .image:eq(' + previousGalleryImage + ')', { zIndex: 1, force3D: true });
        galleryTimeline.set('#domain-part-2-gallery-images .image:eq(' + activeGalleryImage + ')', { scale: 1, opacity: 1, zIndex: 0, force3D: true });
        galleryTimeline.to('#domain-part-2-gallery-images .image:eq(' + previousGalleryImage + ')', 2, { opacity: 0, scale: 1.14, force3D: true, ease: Circ.easeOut });
        galleryTimeline.to('#domain-part-2-gallery-images .image:eq(' + activeGalleryImage + ')', 2, { scale: 1.05, force3D: true, ease: Circ.easeOut }, '-=2');
      };

      return Domain;
    })();

    Sequences.Domain = Domain;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));