var Aneox;
(function (Aneox) {
  'use strict';

  var WineSheet = (function () {

    var isOpen;

    var wineSheetElement;
    var wineSheetContainerElement;
    var wineSheetMenuElement;
    var displayNameElement;
    var imageContainerElement;
    var pdfElements;
    
    var varietalElement;
    var areaElement;
    var orientationElement;
    var subsoilsElement;
    var colorElement;
    var productionElement;
    var descriptionElement;
    var vinificationElement;

    var wine;
    var wineLocale;
    
    var activeSection;

    function WineSheet(locale) {
      this.locale = locale;

      wineSheetElement = $('#wine-sheet');
      wineSheetContainerElement = $('#wine-sheet-container');
      wineSheetMenuElement = $('#wine-sheet-menu');
      displayNameElement = $('#wine-sheet-display-name');
      imageContainerElement = $('#wine-sheet-image-container');
      pdfElements = $('#wine-sheet-pdf,#wine-sheet-menu-pdf');
      
      varietalElement = $('#wine-sheet-varietal span');
      areaElement = $('#wine-sheet-area span');
      orientationElement = $('#wine-sheet-orientation span');
      subsoilsElement = $('#wine-sheet-subsoils span');
      colorElement = $('#wine-sheet-color span');
      productionElement = $('#wine-sheet-production span');
      descriptionElement = $('#wine-sheet-description span');
      vinificationElement = $('#wine-sheet-vinification span');
    }
    
    WineSheet.prototype.show = function (wineId) {
      wine = _.find(Aneox.Data.Data.wines, { id: wineId });
      wineLocale = _.find(wine.locales, { locale: this.locale.getLocale() });
      
      if (!wine || !wineLocale) return;
      
      $('#wines-menu-list li').removeClass('active');
      $('#wines-menu-list li[node-id="' + wineId + '"]').addClass('active');
      
      if (!isOpen) {
        activeSection = $('section:visible');
        
        $('#wine-sheet-details-wrapper').mCustomScrollbar();
        
        var sheetSize = (document.body.clientWidth - 36) * 0.5 + 1;
        var timeline = new TimelineMax();
        
        timeline.set('#wine-sheet-left-wrapper', { x: sheetSize, force3D: true, immediateRender: true });
        timeline.set('#wine-sheet-right-wrapper', { x: -sheetSize, force3D: true, immediateRender: true });
        timeline.set('#wine-sheet-left-content', { x: -sheetSize, force3D: true, immediateRender: true });
        timeline.set('#wine-sheet-right-content', { x: sheetSize, force3D: true, immediateRender: true });
        timeline.set(wineSheetElement, { display: 'block', opacity: 1 });
        
        timeline.to('#wine-sheet-left-wrapper', 1, { x: 0, force3D: true }, 0);
        timeline.to('#wine-sheet-right-wrapper', 1, { x: 0, force3D: true }, 0);
        timeline.to('#wine-sheet-left-content', 1, { x: 0, force3D: true }, 0);
        timeline.to('#wine-sheet-right-content', 1, { x: 0, force3D: true }, 0);
        
        timeline.call(function () { if (activeSection.length) TweenMax.set('section', { clearProps: 'display' }); });
        
        timeline.fromTo('#nav-menu-progress', 0.5, { opacity: 1  }, { opacity: 0, ease: Linear.easeNone }, 0);
        timeline.fromTo('#wines-menu', 0.5, { display: 'block', opacity: 0 }, { opacity: 1, ease: Linear.easeNone }, 0);
        
        applyWineContent(wine, wineLocale);
        
        isOpen = true;
        this.trigger('wineSheetWillShow');
      } else {
        var timeline = new TimelineMax();
        
        var sheetSize = (document.body.clientWidth - 36) * 0.5 + 1;
        
        timeline.to('#wine-sheet-left-inner-content', 0.25, { opacity: 0, force3D: true }, 0);
        timeline.to('#wine-sheet-right-inner-content', 0.25, { opacity: 0, force3D: true }, 0); 
        
        timeline.call(function () { applyWineContent(wine, wineLocale); });
        
        timeline.to('#wine-sheet-left-inner-content', 0.25, { opacity: 1, force3D: true }, 0.75);
        timeline.to('#wine-sheet-right-inner-content', 0.25, { opacity: 1, force3D: true }, 0.75);
      }
    };
    
    var applyWineContent = function (wine, wineLocale) {
      displayNameElement.html(wine.displayName);
      pdfElements.attr('href', wineLocale.pdf);
      
      varietalElement.html(wineLocale.varietal);
      areaElement.html(wineLocale.area);
      orientationElement.html(wineLocale.orientation);
      subsoilsElement.html(wineLocale.subsoils);
      colorElement.html(wineLocale.color);
      productionElement.html(wineLocale.production);
      descriptionElement.html(wineLocale.description);
      vinificationElement.html(wineLocale.vinification);
      
      imageContainerElement.empty();
      imageContainerElement.append('<img class="centered" src="' + wine.image + '" alt="' + wine.name + '">');
    };
    
    WineSheet.prototype.close = function () {
      if (!isOpen) return;
      isOpen = false;
      
      if (activeSection.length) TweenMax.set(activeSection, { display: 'block' });
      TweenMax.fromTo(wineSheetElement, 0.5, { opacity: 1 }, { opacity: 0, display: 'none', onComplete: function () {
        $('#wine-sheet-details-wrapper').mCustomScrollbar("destroy");
      } });
      TweenMax.fromTo('#nav-menu-progress', 0.5, { opacity: 0  }, { opacity: 1, ease: Linear.easeNone });
      TweenMax.fromTo('#wines-menu', 0.5, { opacity: 1 }, { opacity: 0, display: 'none', ease: Linear.easeNone });
      
      this.trigger('wineSheetWillHide');
    };
    
    MicroEvent.mixin(WineSheet);

    return WineSheet;
  })();

  Aneox.WineSheet = WineSheet;
})(Aneox || (Aneox = {}));