var Aneox;
(function (Aneox) {
  (function (Sequences) {
    'use strict';

    var Contact = (function () {

      var self;
      var hasPlayedThroughAnimation;
      
      function Contact(animations, resources) {
        self = this;
        this.animations = animations;
        this.resources = resources;
        
        hasPlayedThroughAnimation = {};
      }
      
      Contact.prototype.handleWindowResize = function (bodyWidth, bodyHeight) {       
      };
      
      Contact.prototype.resetAnimations = function () {
        hasPlayedThroughAnimation = {};
        
        TweenMax.set('#contact-mast .main-logo-dark', { display: 'block', opacity: 0, y: 50, force3D: true, immediateRender: true });
        TweenMax.set('#contact-mast h2 span,#contact-mast h2 em', { opacity: 0, y: 20, force3D: true, immediateRender: true });
        TweenMax.set('#contact-mast-background', { scale: 1, force3D: true, immediateRender: true });
        
        TweenMax.set('#contact-mast-title .title-border-top', { width: 0, force3D: true, immediateRender: true });
        TweenMax.set('#contact-mast-title .title-border-bottom', { width: 0, force3D: true, immediateRender: true });
        TweenMax.set('#contact-mast-title .title-border-left', { height: 0, force3D: true, immediateRender: true });
        TweenMax.set('#contact-mast-title .title-border-right', { height: 0, force3D: true, immediateRender: true });
      };
      
      Contact.prototype.fastForwardAnimations = function () {
        this.resetAnimations();
        this.mastEnterAnimation(true, 0);
      };
      
      Contact.prototype.mastEnterAnimation = function (force, timescale) {
        if (!force && (!self.animations.enabled || hasPlayedThroughAnimation.mast)) return;
        
        if (timescale === undefined) timescale = 1;
        
        var timeline = new TimelineMax();
        
        timeline.to('#contact-mast .main-logo-dark', 3 * timescale, { opacity: 1, y: 0, force3D: true });
        timeline.to('#contact-mast-background', 6 * timescale, { scale: 1.05, force3D: true, ease: Power3.easeOut }, 0 * timescale);
        timeline.staggerTo('#contact-mast h2 span,#contact-mast h2 em', 1 * timescale, { opacity: 1, y: 0, force3D: true }, 0.2, 1.2 * timescale);
        
        timeline.to('#contact-mast-title .title-border-top', 0.4 * timescale, { width: '100%', force3D: true }, 0.4 * timescale);
        timeline.to('#contact-mast-title .title-border-left', 0.4 * timescale, { height: '100%', force3D: true }, 0.4 * timescale);
        timeline.to('#contact-mast-title .title-border-bottom', 0.4 * timescale, { width: '100%', force3D: true }, 0.8 * timescale);
        timeline.to('#contact-mast-title .title-border-right', 0.4 * timescale, { height: '100%', force3D: true }, 0.8 * timescale);
        
        hasPlayedThroughAnimation.mast = true;
      };

      return Contact;
    })();

    Sequences.Contact = Contact;
  })(Aneox.Sequences || (Aneox.Sequences = {}));
})(Aneox || (Aneox = {}));